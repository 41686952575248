export const VALIDATOR_TYPES = {
  caPostalCode: 'postal_ca',
  email: 'email',
  phone: 'phone',
  usZipCode: 'zip_us',
}

/**
 * Validate the specified value against its type.
 *
 * @param {object} params - The function params object.
 * @param {string} params.type - The type of validation. One of VALIDATOR_TYPES.
 * @param {string} params.value - The value to validate.
 *
 * @returns {Boolean} Boolean flag for whether or not value is valid.
 */
export function validator({ type, value }) {
  switch (type) {
    case VALIDATOR_TYPES.email: {
      // Note: Original regex used {2,3} for end, but swapped 3 for 24, as the
      // longest TLD is 24 characters long.
      const regex = /^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,24}$/
      return regex.test(value)
    }

    case VALIDATOR_TYPES.phone: {
      const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
      return value.match(regex) ? true : false
    }

    case VALIDATOR_TYPES.caPostalCode: {
      const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
      return value.match(regex) ? true : false
    }

    case VALIDATOR_TYPES.usZipCode: {
      const regex = /^\d{5}(?:[-\s]\d{4})?$/
      return value.match(regex) ? true : false
    }

    default:
      return false
  }
}
