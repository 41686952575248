import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Grid, Paper, Tab, Tabs, makeStyles } from '@material-ui/core'
import { COLORS } from '../../utils/constants'

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'transparent',
    margin: '0 auto',
    maxWidth: '60rem',
    width: '100%',
  },
  m0: {
    margin: '0',
  },
  navBar: {
    background: COLORS.NAV_BAR_GREY,
    color: COLORS.WHITE,
    height: 'auto',
    padding: '1rem 0',
    width: '100%',
  },
  navContent: {
    margin: '0 auto',
    maxWidth: '60rem',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
  },
  navLabel: {
    [theme.breakpoints.up('md')]: {
      fontSize: '0.875rem',
    },
    fontSize: '0.75rem',
    lineHeight: '1.25',
  },
  navLink: {
    [theme.breakpoints.up('md')]: {
      fontSize: '0.75rem',
      letterSpacing: '0.1375rem',
      padding: '0.75rem 1.25rem 0.75rem',
    },
    color: COLORS.CHARCOAL_DARK_NAV_TABS,
    cursor: 'pointer',
    fontFamily: `'Sequel Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
    fontSize: '0.6875rem',
    fontWeight: '700',
    letterSpacing: '0.0625rem',
    margin: '0 0.5rem',
    opacity: '1',
    padding: '0.8125rem 1.25rem 0.6875rem',
    textTransform: 'uppercase',
    transition: 'all 0.3s',
    '&:disabled': {
      color: COLORS.GREY_5,
      opacity: '1',
    },
    '&:hover': {
      backgroundColor: COLORS.CHARCOAL_DARK_NAV_TABS,
      color: COLORS.GREY_5,
      opacity: '1',
      textDecoration: 'none',
    },
  },
  navTabs: {
    boxShadow: 'none',
  },
}))

export function NavBar({
  activeLink,
  enableProjectLinks,
  idPrefix,
  linkObjects,
  onLinkClick,
}) {
  const classes = useStyles()

  function handleLinkClick(event, value) {
    onLinkClick(event, value)
  }

  return (
    <>
      <Paper elevation={0}>
        <Grid className={classes.navBar} elevation={0} container={true}>
          <Grid className={classes.navContent} item={true}>
            <AppBar
              className={classes.appBar}
              elevation={0}
              position="relative"
            >
              <Tabs
                TabIndicatorProps={{
                  style: {
                    background: COLORS.NAV_TABS_INDICATOR_COLOR,
                    display: 'none',
                  },
                }}
                aria-label="Site Navigation"
                centered={true}
                className={classes.navTabs}
                onChange={handleLinkClick}
                value={activeLink}
                variant="fullWidth"
              >
                {
                  // Sort by object id values.
                  linkObjects && Object.keys(linkObjects).length
                    ? Object.values(linkObjects)
                        .sort((a, b) => (a.id < b.id ? -1 : 1))
                        .map((linkObject) => {
                          return (
                            <Tab
                              aria-controls={`${linkObject.tabPanelPrefix}-${linkObject.id}`}
                              aria-label={linkObject.title}
                              className={[
                                classes.m0,
                                classes.navLabel,
                                classes.navLink,
                              ].join(' ')}
                              disableFocusRipple={true}
                              disableRipple={true}
                              disableTouchRipple={true}
                              disabled={
                                activeLink === linkObject.id ||
                                (linkObject.id !== 0 && !enableProjectLinks)
                              }
                              id={`${idPrefix}${linkObject.id}`}
                              key={`${linkObject.id}`}
                              label={linkObject.title}
                            />
                          )
                        })
                    : null
                }
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

NavBar.propTypes = {
  activeLink: PropTypes.number,
  enableProjectLinks: PropTypes.bool,
  idPrefix: PropTypes.string,
  linkObjects: PropTypes.object,
  onLinkClick: PropTypes.func,
}

NavBar.defaultProps = {
  activeLink: null,
  enableProjectLinks: true,
  idPrefix: 'link',
  linkObjects: {},
  onLinkClick: () => {},
}
