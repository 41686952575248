import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core'
import { COLORS, FUNDING_STATUSES } from '../../utils/constants'
import { CartCard } from './cart-card'
import { DistributionCard } from './distribution-card'
import { TranslationCard } from './translation-card'

const useStyles = makeStyles((theme) => ({
  pc: {
    backgroundColor: COLORS.WHITE,
    color: COLORS.CHARCOAL_DARK_NAV_TABS,
    padding: '1rem 1rem 1rem 1rem',
    // padding: '1rem 1rem 2.6875rem 1rem',
    // pointerEvents: 'none',
    position: 'relative',
    transition: 'all 0.2s ease-in-out',
  },
  pcBorderBottom: {
    backgroundColor: COLORS.CHARCOAL_DARK,
    bottom: '4.5rem', // '1.6875rem',
    height: '0.0625rem',
    left: '2.1875rem',
    position: 'absolute',
    right: '2.1875rem',
  },
  pcBorderLeft: {
    backgroundColor: COLORS.CHARCOAL_DARK,
    bottom: '4.5rem', // '1.6875rem',
    left: '2.1875rem',
    position: 'absolute',
    top: '5.25rem',
    width: '0.0625rem',
  },
  pcBox: {
    position: 'relative',
  },
  pcButton: {
    backgroundColor: `${COLORS.BURNT_ORANGE_LINK}`,
    borderRadius: '4rem',
    color: COLORS.WHITE,
    fontSize: '1rem',
    fontWeight: 600,
    pointerEvents: 'none',
    width: `100%`,
    zIndex: 899,
  },
  pcButtonContainer: {
    marginTop: '2rem', // '1.6875rem',
  },
  pcButtonHover: {
    backgroundColor: COLORS.WHITE,
    color: `${COLORS.BURNT_ORANGE_LINK} !important`,
  },
  pcDisabled: {
    backgroundColor: `${COLORS.WHITE} !important`,
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: `${COLORS.WHITE} !important`,
    },
  },
  pcHitArea: {
    backgroundColor: 'transparent',
    bottom: '0',
    left: '0',
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 898,
    '&:hover': {
      backgroundColor: COLORS.BLACK_25,
    },
  },
  pcIcon: {
    color: `${COLORS.BURNT_ORANGE} !important`,
    height: 'auto',
    left: '1rem',
    margin: '0',
    position: 'absolute',
    top: '1.5rem',
    width: '2.5rem',
  },
  pcOverlay: {
    background: 'rgba(255, 255, 255, 0.6125)',
    bottom: '0',
    left: '0',
    pointerEvents: 'none',
    position: 'absolute',
    right: '0',
    top: '0',
  },
  pcOverlayText: {
    backgroundColor: 'rgba(255, 255, 255, 0.875)',
    borderRadius: '6rem',
    boxShadow: '0 0 1rem rgba(0, 0, 0, 0.125)',
    color: COLORS.GREEN,
    fontSize: '2rem',
    fontWeight: '900',
    left: '50%',
    lineHeight: '2rem',
    margin: '0',
    padding: '1.25rem 2rem',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  pcText: {
    padding: '0.5rem 0 0 3.5rem',
  },
  pcTitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
      lineHeight: '1.75rem',
      marginBottom: '0.5rem',
    },
    fontSize: '1rem',
    fontWeight: 900,
    lineHeight: '1.25rem',
    marginBottom: '0.25rem',
    textTransform: 'unset',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    marginBottom: '0.5rem',
  },
  fontSize: '0.875rem',
  lineHeight: '1.125rem',
  padding: '0 0.25rem',
  textAlign: 'left',
}))

function ProjectCard({
  details,
  icon,
  onClick,
  status,
  style,
  title,
  ...props
}) {
  const classes = useStyles()
  const [isHover, setIsHover] = React.useState(false)
  const [buttonClass, setButtonClass] = React.useState(
    [classes.m0, classes.pcButton].join(' '),
  )
  const [boxClass, setBoxClass] = React.useState(
    [classes.pcBox, style].join(' '),
  )

  React.useEffect(() => {
    console.log('isHover', isHover)
    if (isHover) {
      setButtonClass(() => {
        return [classes.m0, classes.pcButton, classes.pcButtonHover].join(' ')
      })
    } else {
      setButtonClass(() => {
        return [classes.m0, classes.pcButton].join(' ')
      })
    }
  }, [isHover])

  return (
    <>
      <Box
        className={boxClass}
        onClick={onClick}
        onMouseOut={() => {
          setIsHover(false)
        }}
        onMouseOver={() => {
          setIsHover(status === FUNDING_STATUSES.AVAILABLE)
          if (status !== FUNDING_STATUSES.AVAILABLE) {
            setBoxClass(() => {
              return [classes.pcBox, style, classes.pcDisabled].join(' ')
            })
          } else {
            setBoxClass(() => {
              return [classes.pcBox, style].join(' ')
            })
          }
        }}
        {...props}
      >
        <Grid className={classes.pc} container={true}>
          <Grid className={classes.pcIcon} item={true}>
            {icon}
          </Grid>
          <Box className={classes.pcBorderBottom}></Box>
          <Box className={classes.pcBorderLeft}></Box>
          <Grid item={true}>
            <Grid className={classes.pcText} container={true}>
              {title ? (
                <Grid item={true} xs={12}>
                  <Typography className={classes.pcTitle} variant="h2">
                    {title}
                  </Typography>
                </Grid>
              ) : null}
              {details}
            </Grid>
          </Grid>
          <Grid className={classes.pcButtonContainer} container={true}>
            <Button
              className={buttonClass}
              color="primary"
              type="button"
              variant="contained"
            >
              Claim Project
            </Button>
          </Grid>
        </Grid>
        {!status || status !== FUNDING_STATUSES.AVAILABLE ? (
          <Box className={classes.pcOverlay}>
            <Typography className={classes.pcOverlayText}>
              {status || FUNDING_STATUSES.UNAVAILABLE}
            </Typography>
          </Box>
        ) : null}
        <Box className={classes.pcHitArea}></Box>
      </Box>
    </>
  )
}

ProjectCard.propTypes = {
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.element,
  onClick: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  status: PropTypes.string,
  style: PropTypes.string,
}

ProjectCard.defaultProps = {
  details: null,
  icon: null,
  onClick: () => {},
  title: null,
  style: '',
}

export { CartCard, DistributionCard, ProjectCard, TranslationCard }
