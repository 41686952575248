import React from 'react'
import ReactDOM from 'react-dom'
import './assets/fonts/Sequel-Sans/OGJ-Type-Design-Sequel-Sans-Bold-Head.woff'
import './assets/fonts/Sequel-Sans/OGJ-Type-Design-Sequel-Sans-Bold-Obl-Head.woff'
import './assets/fonts/Sequel-Sans/OGJ-Type-Design-Sequel-Sans-Book-Head.woff'
import './assets/fonts/Sequel-Sans/OGJ-Type-Design-Sequel-Sans-Book-Obl-Head.woff'
import './assets/fonts/Sequel-Sans/OGJ-Type-Design-Sequel-Sans-Light-Body.woff'
import './assets/fonts/Sequel-Sans/OGJ-Type-Design-Sequel-Sans-Light-Obl-Body.woff'
import './assets/fonts/Silk-Serif/Silk-Serif-Black-Italic.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-Black.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-Bold-Italic.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-Bold.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-ExtraLight-Italic.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-ExtraLight.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-Light-Italic.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-Light.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-Medium-Italic.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-Medium.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-Regular-Italic.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-Regular.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-SemiBold-Italic.otf'
import './assets/fonts/Silk-Serif/Silk-Serif-SemiBold.otf'
import './index.css'
import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))
