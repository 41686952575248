import React from 'react'
import PropTypes from 'prop-types'

export function DistributionIcon({ color, style }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 47.54 65.72"
        fill="none"
        role="img"
        style={style}
      >
        <title>Distribution Icon</title>
        <circle cx="23.77" cy="54.63" fill={color} r="3.17" />
        <path
          d="M23.77,58.72a4,4,0,0,0-4,4v4h7.92v-4A4,4,0,0,0,23.77,58.72Z"
          fill={color}
          transform="translate(0 -0.92)"
        />
        <circle cx="34.07" cy="51.46" fill={color} r="3.17" />
        <path
          d="M34.07,55.55a4,4,0,0,0-4,4v4H38v-4A4,4,0,0,0,34.07,55.55Z"
          fill={color}
          transform="translate(0 -0.92)"
        />
        <circle cx="43.58" cy="47.5" fill={color} r="3.17" />
        <path
          d="M43.58,51.58a4,4,0,0,0-4,4v4h7.92v-4A4,4,0,0,0,43.58,51.58Z"
          fill={color}
          transform="translate(0 -0.92)"
        />
        <circle cx="13.47" cy="51.46" fill={color} r="3.17" />
        <path
          d="M13.47,55.55a4,4,0,0,0-4,4v4h7.92v-4A4,4,0,0,0,13.47,55.55Z"
          fill={color}
          transform="translate(0 -0.92)"
        />
        <circle cx="3.96" cy="47.5" fill={color} r="3.17" />
        <path
          d="M4,51.58a4,4,0,0,0-4,4v4H7.92v-4A4,4,0,0,0,4,51.58Z"
          fill={color}
          transform="translate(0 -0.92)"
        />
        <path
          d="M3.17,38.12v3.27L2,40.63l-.88,1.31,2.38,1.59a.78.78,0,0,0,.88,0l2.38-1.59L5.9,40.63l-1.15.76V38.91H23v2.37H13.47a.79.79,0,0,0-.79.8v3.27l-1.15-.76-.88,1.32L13,47.49a.78.78,0,0,0,.88,0l2.38-1.58-.88-1.32-1.15.76V42.87H23v5.65l-1.15-.76L21,49.07l2.38,1.59a.78.78,0,0,0,.88,0l2.38-1.59-.88-1.31-1.15.76V42.87h8.72v2.48l-1.15-.76-.88,1.32,2.38,1.58a.78.78,0,0,0,.88,0l2.38-1.58L36,44.59l-1.15.76V42.08a.79.79,0,0,0-.79-.8H24.56V38.91H42.78v2.48l-1.14-.76-.88,1.31,2.38,1.59a.78.78,0,0,0,.88,0l2.37-1.59-.88-1.31-1.14.76V38.12a.8.8,0,0,0-.79-.8h-19v-4H23v4H4A.79.79,0,0,0,3.17,38.12Z"
          fill={color}
          transform="translate(0 -0.92)"
        />
        <path
          d="M10,.92V25.76c5.48,0,9.56,3,12.84,5.7V6.06C19.22,3,15.13.91,10,.92Zm27.41,0C32.29.91,28.2,3,24.57,6.06v25.4c3.28-2.74,7.37-5.73,12.85-5.7ZM5.73,4.34V29.18l15,2.57c-2.84-2.15-6.44-4.3-10.7-4.28H9.15a.89.89,0,0,1-.86-.86V4.71Zm36,0-2.57.37v21.9a.89.89,0,0,1-.86.86h-.85c-4.27,0-7.86,2.13-10.71,4.28l15-2.57Z"
          fill={color}
          transform="translate(0 -0.92)"
        />
      </svg>
    </>
  )
}

DistributionIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
}

DistributionIcon.defaultProps = {
  color: '#000000',
  style: { position: 'relative' },
}
