export const WEBHOOK_URLS = {
  holdProject: 'https://hooks.zapier.com/hooks/catch/466200/bbh1gm2/',
  releaseProject: 'https://hooks.zapier.com/hooks/catch/466200/bbhfipk/',
  submitProjectPayment: 'https://hooks.zapier.com/hooks/catch/466200/bbj0a9g/',
}

/**
 * Trigger and send a webhook to the specified URL.
 *
 * @param {object} params - The function params object.
 * @param {object} params.data - The data object to send to the webhook.
 * @param {string} params.url - The url to which to send the webhook trigger.
 *
 * @returns {object} - An object with callback keys and values from the specified webhook url response.
 *
 * @throws {Error}
 *
 */
export async function triggerWebhook({ data, url }) {
  let errorReason
  if (!data) {
    errorReason = 'No data object provided.'
  }
  if (errorReason) {
    throw new Error(`Unable to trigger webhook: ${errorReason}`)
  }

  const requestOptions = {
    body: JSON.stringify(data),
    method: 'POST',
  }
  const response = await fetch(url, requestOptions)
  const responseData = await response.json()

  if (typeof responseData !== 'object') {
    throw new Error(
      `Unsuccessful trigger to webhook url '${url}': ${JSON.stringify(
        responseData,
      )}`,
    )
  }

  return responseData
}
