import React from 'react'
import PropTypes from 'prop-types'
import {
  Badge,
  Grid,
  IconButton,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { COLORS } from '../../utils/constants'

const StyledBadge = withStyles(() => ({
  badge: {
    backgroundColor: COLORS.WHITE,
    border: `2px solid ${COLORS.NAV_BAR_BLACK}`,
    color: COLORS.CHARCOAL_DARK,
    fontSize: '0.875rem',
    fontWeight: 'bold',
    padding: '0 0.25rem',
    right: '-0.1875rem',
    top: '0.75rem',
  },
}))(Badge)

const useStyles = makeStyles(() => ({
  countdownText: {
    fontFamily: 'monospace',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  icon: {
    color: COLORS.WHITE,
    fontSize: '1.5rem',
  },
  iconButtonGrid: {
    borderLeft: '1px solid rgba(255, 255, 255, 0.35)',
    marginLeft: '0.75rem',
  },
}))

export function Cart({ count, countdownDisplay, onClick, style }) {
  const classes = useStyles()

  return (
    <>
      <Grid className={style} container={true} onClick={onClick}>
        {countdownDisplay ? (
          <Grid className={classes.countdownText} item={true}>
            {countdownDisplay}
          </Grid>
        ) : null}
        <Grid className={classes.iconButtonGrid} item={true}>
          <IconButton aria-label="cart">
            <StyledBadge badgeContent={count}>
              <ShoppingCartIcon className={classes.icon} />
            </StyledBadge>
          </IconButton>
        </Grid>
      </Grid>
    </>
  )
}

Cart.propTypes = {
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  countdownDisplay: PropTypes.element,
  onClick: PropTypes.func,
  style: PropTypes.string,
}

Cart.defaultProps = {
  count: 0,
  countdownDisplay: null,
  onClick: () => {},
  style: '',
}
