import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { COLORS, PROJECT_TYPES } from '../../utils/constants'
import { Project } from '../../classes'

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    position: 'absolute',
    right: '0.25rem',
    top: '0.25rem',
  },
  pc: {
    backgroundColor: COLORS.WHITE,
    color: COLORS.CHARCOAL_DARK_NAV_TABS,
    padding: '1rem 1rem 1.25rem 1rem',
    pointerEvents: 'none',
    position: 'relative',
  },
  pcBox: {
    position: 'relative',
  },
  pcIcon: {
    color: `${COLORS.BURNT_ORANGE} !important`,
    height: 'auto',
    left: '1rem',
    margin: '0',
    position: 'absolute',
    top: '1.5rem',
    width: '2.5rem',
  },
  pcLabel: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.75rem',
      textAlign: 'left',
      '& > .MuiTypography-body1': {
        margin: '0',
        '&:last-of-type': {
          margin: '0',
        },
      },
    },
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.375rem',
    padding: '0 0.25rem',
    textAlign: 'right',
  },
  pcText: {
    padding: '0.5rem 0 0 3.5rem',
  },
  pcTitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
      lineHeight: '1.75rem',
      marginBottom: '0.5rem',
    },
    fontSize: '1rem',
    fontWeight: 900,
    lineHeight: '1.25rem',
    marginBottom: '0.25rem',
    textTransform: 'unset',
  },
  pcValue: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.25rem',
      textAlign: 'left',
      '& > .MuiTypography-body1': {
        margin: '0',
        '&:last-of-type': {
          margin: '0',
        },
      },
    },
    fontSize: '1rem',
    lineHeight: '1.375rem',
    padding: '0 0.25rem',
    textAlign: 'left',
  },
}))

export function CartCard({ cardData, icon, onRemoveClick, style, ...props }) {
  const classes = useStyles()

  function handleRemoveClick(event) {
    onRemoveClick(event, cardData)
  }

  return (
    <>
      <Box className={[classes.pcBox, style].join(' ')} {...props}>
        <Grid className={classes.pc} container={true}>
          <Grid className={classes.pcIcon} item={true}>
            {icon}
          </Grid>
          <Grid item={true}>
            <Grid className={classes.pcText} container={true}>
              {cardData.Name && cardData.Country ? (
                <Grid item={true} xs={12}>
                  <Typography className={classes.pcTitle} variant="h2">
                    {cardData.Type === PROJECT_TYPES.DISTRIBUTION.type ? (
                      <>
                        {cardData.Country} <i>({cardData.Language})</i>
                      </>
                    ) : (
                      <>
                        {cardData.Name} - {cardData.Country}{' '}
                        <i>({cardData.Language})</i>
                      </>
                    )}
                  </Typography>
                </Grid>
              ) : null}
              {cardData.Type === PROJECT_TYPES.DISTRIBUTION.type ? (
                <>
                  <Grid className={classes.pcLabel} item={true} md={2} xs={12}>
                    <Typography Typography variant="body1">
                      <span>Resource:</span>
                    </Typography>
                  </Grid>
                  <Grid className={classes.pcValue} item={true} md={10} xs={12}>
                    <Typography variant="body1">
                      <span>
                        {cardData.Name}{' '}
                        <i>
                          (Quantity: {cardData.QuantityLabel}, Impact:{' '}
                          {cardData.ImpactLabel})
                        </i>
                      </span>
                    </Typography>
                  </Grid>
                  <Grid className={classes.pcLabel} item={true} md={2} xs={12}>
                    <Typography variant="body1">
                      <span>Investment:</span>
                    </Typography>
                  </Grid>
                  <Grid className={classes.pcValue} item={true} md={10} xs={12}>
                    <Typography variant="body1">
                      <span>{cardData.Investment}</span>
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid className={classes.pcLabel} item={true} md={3} xs={12}>
                    <Typography variant="body1">
                      <span>Investment:</span>
                    </Typography>
                  </Grid>
                  <Grid className={classes.pcValue} item={true} md={9} xs={12}>
                    <Typography variant="body1">
                      <span>{cardData.Investment}</span>
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box className={classes.deleteIcon}>
          <IconButton onClick={handleRemoveClick}>
            <DeleteIcon color="inherit" />
          </IconButton>
        </Box>
      </Box>
    </>
  )
}

CartCard.propTypes = {
  cardData: PropTypes.shape(Project).isRequired,
  icon: PropTypes.element,
  onRemoveClick: PropTypes.func,
  style: PropTypes.string,
}

CartCard.defaultProps = {
  icon: null,
  onRemoveClick: () => {},
  style: '',
}
