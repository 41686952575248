import React from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import { Fade, LinearProgress, makeStyles } from '@material-ui/core'
import { Box, Container, Grid, Typography } from '@material-ui/core'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { GoogleSpreadsheet } from 'google-spreadsheet'
import {
  ADVANCED_AUCTION_GOOGLE_SHEET,
  CART_TIMEOUT_DURATION,
  COLORS,
  CONFIRMATION_DIALOG_DATA,
  DATA_RETRIEVAL_TIME_DURATION,
  FUNDING_STATUSES,
  IS_LIVE,
  PROJECT_TYPES,
  STRIPE_KEYS,
  TIMER_COMMANDS,
} from './utils/constants'
import {
  formatUSD,
  getProjectType,
  parseGoogleSheetData,
} from './utils/helpers'
import {
  Cart,
  CheckoutDialog,
  ConfirmationDialog,
  Header,
  NavBar,
} from './components'
import { DistributionCard, TranslationCard } from './components/project-card'
import { API_URLS, postAPIData } from './utils/api'
import { triggerWebhook, WEBHOOK_URLS } from './utils/webhooks'

// Google Spreadsheet.
const sheet = new GoogleSpreadsheet(ADVANCED_AUCTION_GOOGLE_SHEET.sheetId)

// Custom Mui Theme.
const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '0',
        cursor: 'pointer',
        fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
      },
    },
    MuiFormControlLabel: {
      label: {
        margin: '.1875rem 0',
      },
    },
    MuiDialog: {
      root: {
        zIndex: '9999 !important',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '0.75rem 1.5rem 1.5rem',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0.75rem 1.5rem',
        '&:first-child': {
          paddingTop: '1.5rem',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '1rem 1.5rem',
        '& > .MuiTypography-h6': {
          fontSize: '2rem',
          fontWeight: '600',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
      },
      input: {
        fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: COLORS.CHARCOAL_DARK,
        '&.Mui-checked': {
          color: COLORS.CHARCOAL_DARK,
        },
        '&:hover': {
          backgroundColor: 'rgba(96, 91, 168, 0.1)',
          color: COLORS.CHARCOAL_DARK,
        },
      },
    },
    MuiTab: {
      root: {
        fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
        textTransform: 'unset',
      },
      textColorInherit: {
        backgroundColor: COLORS.GREY_5,
        borderRadius: '5rem',
        '&.Mui-selected': {
          backgroundColor: COLORS.CHARCOAL_DARK_NAV_TABS,
          color: COLORS.GREY_5,
        },
      },
    },
    MuiTypography: {
      root: {
        fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
      },
      body1: {
        fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
        margin: '1rem 0',
        '&:last-of-type': {
          marginBottom: '0',
        },
      },
      h1: {
        fontFamily: `'Silk Serif', 'Sequel Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
        fontWeight: '400',
      },
      h2: {
        fontFamily: `'Silk Serif', 'Sequel Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
        fontSize: '2.75rem',
        fontWeight: '400',
        margin: '1rem 0',
        textTransform: 'uppercase',
      },
      h3: {
        fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
        fontSize: '1.375rem',
        fontWeight: '400',
        margin: '1rem 0',
      },
      h4: {
        fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
        fontSize: '1.0625rem',
        fontWeight: '400',
        margin: '1rem 0',
      },
      h5: {
        fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
      },
      h6: {
        fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
      },
    },
  },
  palette: {
    primary: {
      main: COLORS.CHARCOAL_DARK,
    },
    secondary: {
      main: COLORS.WHITE,
    },
  },
})

// Custom Styles.
const useStyles = makeStyles((theme) => ({
  root: {
    color: COLORS.CHARCOAL_DARK,
    maxWidth: '100%',
    padding: '0',
  },
  colorCharcoalDark: {
    color: COLORS.CHARCOAL_DARK_NAV_TABS,
  },
  cursorNotAllowed: {
    backgroundColor: `${COLORS.WHITE} !important`,
    cursor: 'not-allowed !important',
  },
  defaultContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '58rem',
      padding: '1.5rem 1rem',
    },
    padding: '1rem',
    margin: '0 auto',
    maxWidth: 'calc(100% - 2rem)',
    width: 'calc(100% - 2rem)',
  },
  defaultContainerMain: {
    paddingTop: '3rem',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    margin: '4rem 0 0',
    padding: '1rem',
    width: '100%',
  },
  footerContent: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '60rem',
      padding: '1rem',
    },
    padding: '0',
    position: 'relative',
    width: '100%',
  },
  grey: {
    color: COLORS.GREY_76,
  },
  hide: {
    display: 'none',
  },
  iconLink: {
    marginBottom: '-0.375rem',
  },
  loadingContainer: {
    margin: '0 auto',
    padding: '6rem 0 2rem',
    textAlign: 'center',
  },
  loadingProgress: {
    margin: '0 auto',
    maxWidth: '16rem',
    width: '75%',
  },
  m0: {
    margin: '0',
  },
  mb0: {
    marginBottom: '0',
  },
  mb1: {
    marginBottom: '1rem',
  },
  mb2: {
    marginBottom: '2rem',
  },
  mb3: {
    marginBottom: '3rem',
  },
  mt0: {
    marginTop: '0',
  },
  mt1: {
    marginTop: '1rem',
  },
  mt2: {
    marginTop: '2rem',
  },
  mt3: {
    marginTop: '3rem',
  },
  mtb1: {
    margin: '1rem 0',
  },
  mtb2: {
    margin: '2rem 0',
  },
  mtb3: {
    margin: '3rem 0',
  },
  mw100: {
    maxWidth: '100%',
  },
  navCart: {
    [theme.breakpoints.up('md')]: {
      right: 'calc(50% - 28rem)',
    },
    alignItems: 'center',
    backgroundColor: COLORS.NAV_BAR_BLACK,
    borderRadius: '0.25rem',
    boxShadow: '0 0 0.325rem rgba(0, 0, 0, 0.75)',
    cursor: 'pointer',
    padding: '2rem 1.5rem 1rem 1.5rem',
    position: 'fixed',
    right: '0.5rem',
    top: '-1rem',
    width: 'auto',
    zIndex: '9998',
    '&:hover': {
      background: COLORS.NAV_BAR_BLACK,
    },
  },
  noBullets: {
    listStyle: 'none',
    marginLeft: '0',
    paddingLeft: '0',
  },
  pcContainer: {
    [theme.breakpoints.up('md')]: {
      padding: '1.5rem 1rem',
    },
    flexGrow: 1,
    padding: '1rem',
    margin: '0 auto',
    maxWidth: '60rem',
    width: '100%',
  },
  pc: {
    [theme.breakpoints.up('md')]: {
      margin: '0.5rem',
      width: 'calc(50% - 1rem - 2px)',
    },
    border: '1px solid #ccc',
    color: COLORS.CHARCOAL_DARK_NAV_TABS,
    cursor: 'pointer',
    margin: '0.5rem 0',
    width: 'calc(100% - 2px)',
  },
  quote: {
    fontSize: '1.25rem',
    fontWeight: '300',
  },
  textUpper: {
    textTransform: 'uppercase',
  },
  totalInvestment: {
    fontSize: '1.7rem',
  },
  ulLiMt025: {
    '& > li': {
      marginTop: '0.25rem',
    },
  },
  ulLiMt05: {
    '& > li': {
      marginTop: '0.5rem',
    },
  },
  ulMt0: {
    marginTop: '0',
  },
  w100: {
    width: '100%',
  },
}))

/**
 * @typedef GoogleSpreadsheet
 *
 * @param {string} autoRecalc - The recalculation interval option.
 * @param {object} defaultFormat - The format of a cell.
 * @param {object} iterativeCalculationSettings - The circular dependency resolution calculation value.
 * @param {string} locale - The document locale/language (ISO code: e.g. 'en', 'es', 'it').
 * @param {number} sheetCount - Count of child worksheets.
 * @param {object} sheetsById - Child worksheets, keyed by their `sheetId`.
 * @param {Array} sheetsByIndex - Array of worksheets, ordered by their index.
 * @param {object} sheetsByTitle - Child worksheets, keyed by their `title` (beware of title conflicts).
 * @param {string} spreadsheetId - The document id.
 * @param {object} spreadsheetTheme - The spreadsheet theme object.
 * @param {string} timeZone - The document timezone (CLDR format: e.g. 'America/New York', 'GMT-07:00').
 * @param {string} title - The document title.
 *
 * @see {@link https://theoephraim.github.io/node-google-spreadsheet/#/classes/google-spreadsheet}.
 */

// Load Stripe.
const stripePromise = loadStripe(
  IS_LIVE ? STRIPE_KEYS.PUBLISHABLE.LIVE : STRIPE_KEYS.PUBLISHABLE.TEST,
)

/**
 * Main App component.
 *
 * @returns {React.ReactElement} The App component.
 */
function App() {
  const classes = useStyles()
  const navLinkIdPrefix = 'link'
  const today = new Date()
  const urlPath = window.location.pathname

  // Refs
  const cartCountdownRef = React.useRef()
  const checkoutCountdownRef = React.useRef()
  const dataRetrievalCountdownRef = React.useRef()

  // States
  const [activeProjectType, setActiveProjectType] = React.useState(
    getProjectType(0),
  )
  const [cart, setCart] = React.useState([])
  const [checkoutDialogOpen, setCheckoutDialogOpen] = React.useState(false)
  const [confirmationDialogData, setConfirmationDialogData] = React.useState(
    CONFIRMATION_DIALOG_DATA.creditCardError,
  )
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false)
  const [currentCartCount, setCurrentCartCount] = React.useState(-1)
  const [list, setList] = React.useState()
  const [projectTotals, setProjectTotals] = React.useState({
    projectsCount: 0,
    totalInvestment: 0,
  })
  const [selectedProjectId, setSelectedProjectId] = React.useState(null)
  const [sheetsData, setSheetsData] = React.useState([])
  const [sheetsLoaded, setSheetsLoaded] = React.useState(false)

  // eslint-disable-next-line no-unused-vars
  const [tabletopObject, setTabletopObject] = React.useState(null)

  // Countdowns
  const [dataRetrievalExpiration, setDataRetrievalExpiration] = React.useState(
    Date.now() + DATA_RETRIEVAL_TIME_DURATION,
  )
  const [timeRemaining, setTimeRemaining] = React.useState(
    Date.now() + CART_TIMEOUT_DURATION,
  )

  // Custom countdown renderer to ensure proper leading-zeros and timer format.
  const countdownRenderer = ({ minutes, seconds }) => {
    return (
      <span>
        {minutes}:{zeroPad(seconds)}
      </span>
    )
  }
  const dataCountdownRenderer = () => {
    return <span className={classes.hide}></span>
  }

  // Convenience app reset function.
  function resetApp() {
    setCart([])
    setCheckoutDialogOpen(false)
    setConfirmationDialogData(CONFIRMATION_DIALOG_DATA.addToCart)
    setConfirmationDialogOpen(false)
    setCurrentCartCount(-1)
    setSelectedProjectId(null)
    initServiceAccount()
  }

  // Add the project with the specified projectId to the cart.
  async function addProjectToCart(projectId) {
    setConfirmationDialogOpen(false)

    // Send project ID data to webhook.
    try {
      const webhookResponse = await triggerWebhook({
        data: {
          id: projectId,
        },
        url: WEBHOOK_URLS.holdProject,
      })
      if (webhookResponse.status === 'success') {
        // Update cart and project statuses.
        const currentCart = cart && cart.length ? [...cart] : []
        currentCart.push(sheetsData[list][projectId])
        setCart(currentCart)

        // Set sheetsData with pending status for added projectId to mock
        // real-time data refresh while using the set timer for data retrieval.
        setSheetsData((prevSheetsData) => {
          const updatedData = {
            ...prevSheetsData,
          }
          updatedData[list][projectId].Status = FUNDING_STATUSES.PENDING
          return updatedData
        })
        resetDataRetrievalTimer()
      } else {
        console.error('Webhook Response Error')
        console.error(webhookResponse)
        setConfirmationDialogData(CONFIRMATION_DIALOG_DATA.addToCartError)
        setConfirmationDialogOpen(true)
      }
    } catch (webhookError) {
      console.error('Webhook Error')
      console.error(webhookError)
      setConfirmationDialogData(CONFIRMATION_DIALOG_DATA.addToCartError)
      setConfirmationDialogOpen(true)
    }
  }

  // Remove the specified projectId from the cart.
  async function removeProjectFromCart(projectId) {
    setConfirmationDialogOpen(false)

    // Send project ID data to webhook.
    try {
      const webhookResponse = await triggerWebhook({
        data: {
          id: [projectId],
        },
        url: WEBHOOK_URLS.releaseProject,
      })
      if (webhookResponse.status === 'success') {
        // Update cart and project statuses.
        const projectData = cart.find((p) => {
          return p.Id === projectId
        })
        const projectIndex = cart.indexOf(projectData)
        if (projectIndex > -1) {
          const currentCart = [...cart]
          currentCart.splice(projectIndex, 1)
          setCart(currentCart)
        }

        // Set sheetsData with available status for removed projectId(s).
        setSheetsData((prevSheetsData) => {
          const updatedData = {
            ...prevSheetsData,
          }
          updatedData[list][projectId].Status = FUNDING_STATUSES.AVAILABLE
          return updatedData
        })
        resetDataRetrievalTimer()
      } else {
        console.error('Webhook Response Error')
        console.error(webhookResponse)
        setConfirmationDialogData(CONFIRMATION_DIALOG_DATA.removeFromCartError)
        setConfirmationDialogOpen(true)
      }
    } catch (webhookError) {
      console.error('Webhook Error')
      console.error(webhookError)
      setConfirmationDialogData(CONFIRMATION_DIALOG_DATA.removeFromCartError)
      setConfirmationDialogOpen(true)
    }
  }

  // Handle card click events.
  function handleCardClick(project) {
    const projectId = project.Id
    setSelectedProjectId(projectId)
    setConfirmationDialogData(CONFIRMATION_DIALOG_DATA.addToCart)
    setConfirmationDialogOpen(true)
  }

  function handleCheckoutRemoveProject({ projectData }) {
    const projectId = projectData.Id
    setSelectedProjectId(projectId)
    setConfirmationDialogData(CONFIRMATION_DIALOG_DATA.removeFromCart)
    setConfirmationDialogOpen(true)
  }

  // Handle confirmation dialog confirm click events.
  function handleConfirmDialogConfirmClick() {
    switch (confirmationDialogData) {
      case CONFIRMATION_DIALOG_DATA.addToCart: {
        return addProjectToCart(selectedProjectId)
      }
      case CONFIRMATION_DIALOG_DATA.checkoutTimerComplete: {
        return resetApp()
      }
      case CONFIRMATION_DIALOG_DATA.removeFromCart: {
        return removeProjectFromCart(selectedProjectId)
      }
      default: {
        return setConfirmationDialogOpen(false)
      }
    }
  }

  // Handle data retrieval countdown complete event.
  function handleDataRetrievalCountdownComplete() {
    // fetchData()
  }

  // Handle cart countdown complete event.
  async function handleCountdownComplete() {
    const cartIds = []
    cart.forEach((project) => {
      cartIds.push(project.Id)
    })

    // Send project ID data to webhook.
    try {
      const webhookResponse = await triggerWebhook({
        data: {
          id: cartIds,
        },
        url: WEBHOOK_URLS.releaseProject,
      })
      if (webhookResponse.status === 'success') {
        // Webhook Success: Project removed from on-hold.
      } else {
        console.error('Webhook Response Error')
        console.error(webhookResponse)
      }
    } catch (webhookError) {
      console.error('Webhook Error')
      console.error(webhookError.message)
    }

    // Show user dialog with message about cart timer expiring.
    setConfirmationDialogData(CONFIRMATION_DIALOG_DATA.checkoutTimerComplete)
    setConfirmationDialogOpen(true)
  }

  // Handle link click.
  function handleLinkClick(event, value) {
    if (event) {
      event.preventDefault()
    }
    const projectId = parseInt(value, 10)
    setActiveProjectType(getProjectType(projectId))
  }

  // Handle timer commands.
  function handleTimerCommand(command) {
    switch (command) {
      case TIMER_COMMANDS.PAUSE: {
        if (cartCountdownRef && cartCountdownRef.current) {
          cartCountdownRef.current.pause()
        }
        if (checkoutCountdownRef && checkoutCountdownRef.current) {
          checkoutCountdownRef.current.pause()
        }
        return
      }
      case TIMER_COMMANDS.RESET: {
        setTimeRemaining(() => {
          return Date.now() + CART_TIMEOUT_DURATION
        })
        return
      }
      case TIMER_COMMANDS.START: {
        if (cartCountdownRef && cartCountdownRef.current) {
          cartCountdownRef.current.start()
        }
        if (checkoutCountdownRef && checkoutCountdownRef.current) {
          checkoutCountdownRef.current.start()
        }
        return
      }
      case TIMER_COMMANDS.STOP: {
        if (cartCountdownRef && cartCountdownRef.current) {
          cartCountdownRef.current.stop()
        }
        if (checkoutCountdownRef && checkoutCountdownRef.current) {
          checkoutCountdownRef.current.stop()
        }
        return
      }
      default:
        return
    }
  }

  // Convenience function to clear the cart, called from `onbeforeunload`.
  async function clearCart() {
    const cartIds = []
    cart.forEach((project) => {
      cartIds.push(project.Id)
      // Set sheetsData with available status for removed projectIds to mock
      // real-time data refresh before it refreshes.
      setSheetsData((prevSheetsData) => {
        const updatedData = {
          ...prevSheetsData,
        }
        updatedData[list][project.Id].Status = FUNDING_STATUSES.AVAILABLE
        return updatedData
      })
      resetDataRetrievalTimer()
    })
    setCart([])

    // Send project ID data to webhook.
    try {
      const webhookResponse = await triggerWebhook({
        data: {
          id: cartIds,
        },
        url: WEBHOOK_URLS.releaseProject,
      })
      if (webhookResponse.status === 'success') {
        // Webhook Success: Project removed from on-hold.
      } else {
        console.error('Webhook Response Error')
        console.error(webhookResponse)
      }
    } catch (webhookError) {
      console.error('Webhook Error')
      console.error(webhookError.message)
    }
  }

  // Convenience function to trigger API call for confirmation email sending.
  // Note: This is not necessarily the best solution for passing the data for
  // the claimed projects, but rather than letting the PHP logic parse and
  // decide how to mark up the HTML, the choice was made to pass in all of the
  // data with markup to avoid complication.
  async function sendConfirmationEmail(cartData, userData) {
    try {
      const claimedProjects = []
      cartData.map((cartItem) => {
        let liDetails
        let liTitle
        if (cartItem.Type === PROJECT_TYPES.DISTRIBUTION.type) {
          liDetails = `Resource: ${cartItem.Name} <em>(Quantity: ${cartItem.QuantityLabel}, Impact: ${cartItem.ImpactLabel})</em><br/>Investment: ${cartItem.Investment}`
          liTitle = `${cartItem.Country} <em>(${cartItem.Language})</em>`
        } else {
          liDetails = `Investment: ${cartItem.Investment}`
          liTitle = `${cartItem.Name} &ndash; ${cartItem.Country} <em>(${cartItem.Language})</em>`
        }
        claimedProjects.push(
          encodeURIComponent(`<li><b>${liTitle}</b><br/>${liDetails}</li>`),
        )
      })
      const localeStringOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      const now = new Date()

      const apiResponse = await postAPIData({
        data: {
          address1: userData.address1,
          address2: userData.address2,
          claimed_projects: claimedProjects.join(''),
          city: userData.city,
          company: userData.company,
          email: userData.email,
          full_name: userData.fullName,
          payment_type: userData.paymentType,
          phone: userData.phone,
          state_province: userData.stateProvince,
          transaction_date: now.toLocaleDateString(
            undefined,
            localeStringOptions,
          ),
          zip_postal: userData.zipPostal,
        },
        url: API_URLS.sendConfirmationEmail,
        urlPath: `${window.location.origin}/`,
      })
      if (apiResponse.success === '1') {
        // API Success: Confirmation email sent.
      } else {
        console.error('API Response Error: Confirmation Email')
        console.error(apiResponse)
      }
    } catch (apiError) {
      console.error('API Error: Confirmation Email')
      console.error(apiError.message)
    }
  }

  // Google Spreadsheets initialization handler to parse and set sheetsData.
  async function onGoogleSheetsInit(data) {
    const formattedData = await parseGoogleSheetData(data)
    setSheetsData(() => {
      return formattedData
    })
    resetDataRetrievalTimer()

    // If URL path is at `/hr2`, use the 2nd sheet tab data. Otherwise, it
    // will default to the first sheet tab.
    const listKeys = Object.keys(formattedData).sort()
    const listToUse =
      urlPath.indexOf('hr2') > -1 && listKeys.length > 1
        ? listKeys[1]
        : listKeys[0]
    setList(listToUse)

    // Calculate project totals.
    let totalInvestment = 0
    Object.values(formattedData[listToUse]).map((project) => {
      totalInvestment += Number(project.Investment.replace(/[^0-9.]/g, ''))
    })

    // Set project totals.
    setProjectTotals(() => {
      return {
        projectsCount: Object.keys(formattedData[listToUse]).length,
        totalInvestment: `$${formatUSD(totalInvestment)}`,
      }
    })

    // Set sheets loaded here after all is initialized and parsed.
    setSheetsLoaded(true)
  }

  // Convenience function to get a new expiry timestamp for data retrieval.
  function getNewExpiryTimestamp() {
    return Date.now() + DATA_RETRIEVAL_TIME_DURATION
  }

  // Convenience function to reset and kick off repeating data retrieval timer.
  function resetDataRetrievalTimer() {
    setDataRetrievalExpiration(() => {
      return getNewExpiryTimestamp()
    })
    if (dataRetrievalCountdownRef && dataRetrievalCountdownRef.current) {
      dataRetrievalCountdownRef.current.start()
    }
  }

  // Effect to set time remaining for checkout.
  React.useEffect(() => {
    if (cart && cart.length && cart.length !== currentCartCount) {
      setCurrentCartCount(cart.length > 0 ? cart.length : -1)
      setTimeRemaining(() => {
        return Date.now() + CART_TIMEOUT_DURATION
      })
      handleTimerCommand(TIMER_COMMANDS.START)
    } else {
      handleTimerCommand(TIMER_COMMANDS.RESET)
    }
  }, [cart])

  /**
   * Convenience function to init and load sheets data.
   */
  async function initServiceAccount() {
    try {
      await sheet.useServiceAccountAuth({
        client_email: ADVANCED_AUCTION_GOOGLE_SHEET.clientEmail,
        private_key: ADVANCED_AUCTION_GOOGLE_SHEET.privateKey,
      })
      await sheet.loadInfo()
      onGoogleSheetsInit(sheet)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error initializing Google Sheets.', error)
    }
  }

  /**
   * Single-run effect. Initializes google-spreadsheets object with service
   * account credentials and loads sheet info (document and worksheets).
   */
  React.useEffect(() => {
    if (sheet) {
      initServiceAccount()
      setActiveProjectType(getProjectType(0))
    }
  }, [])

  // Override to show confirm dialog and clear cart automatically when items are
  // present in the cart. Otherwise, reset function to override possibly-already
  // overridden function.
  if (cart && cart.length) {
    window.onbeforeunload = async (e) => {
      e.preventDefault()
      await clearCart()
      return ''
    }
  } else {
    window.onbeforeunload = null
  }

  function LoadingView() {
    return (
      <Box
        className={[
          classes.colorCharcoalDark,
          classes.defaultContainer,
          classes.loadingContainer,
        ].join(' ')}
      >
        <LinearProgress
          className={classes.loadingProgress}
          variant="indeterminate"
        />
        <Typography variant="body1">Fetching data...</Typography>
      </Box>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.root}>
        <Countdown
          autoStart={true}
          date={dataRetrievalExpiration}
          onComplete={handleDataRetrievalCountdownComplete}
          ref={dataRetrievalCountdownRef}
          renderer={dataCountdownRenderer}
        />
        <Header
          cart={
            cart.length ? (
              <Cart
                count={cart.length || '0'}
                countdownDisplay={
                  cart.length ? (
                    <Countdown
                      autoStart={true}
                      date={timeRemaining}
                      onComplete={handleCountdownComplete}
                      ref={cartCountdownRef}
                      renderer={countdownRenderer}
                      zeroPadTime={4}
                    />
                  ) : null
                }
                onClick={() => {
                  setCheckoutDialogOpen(true)
                }}
                style={classes.navCart}
              />
            ) : null
          }
          subtitle={
            activeProjectType.type.toLowerCase() !== 'about'
              ? 'Messenger Cup'
              : 'Auction Preview'
          }
          title={
            <span>
              {activeProjectType.type.toLowerCase() !== 'about' ? (
                <>
                  {`${activeProjectType ? activeProjectType.type : ''}`}{' '}
                  Projects
                </>
              ) : (
                <>messenger cup</>
              )}
            </span>
          }
        />
        <NavBar
          activeLink={activeProjectType.id}
          enableProjectLinks={sheetsLoaded}
          idPrefix={navLinkIdPrefix}
          linkObjects={PROJECT_TYPES}
          onLinkClick={handleLinkClick}
        />
        {!sheetsLoaded ? (
          <LoadingView />
        ) : (
          <Fade in={sheetsLoaded}>
            {/* Project Cards */}
            {activeProjectType &&
            activeProjectType.type.toLowerCase() !== 'about' ? (
              <Grid
                container
                className={[classes.pcContainer, classes.root].join(' ')}
              >
                {sheetsData && sheetsData[list]
                  ? Object.values(sheetsData[list]).map((project) => {
                      if (
                        project.Type === PROJECT_TYPES.TRANSLATION.type &&
                        activeProjectType === PROJECT_TYPES.TRANSLATION
                      ) {
                        return (
                          <TranslationCard
                            cardData={project}
                            id={project.Id}
                            key={project.Id}
                            onClick={
                              project.Status === FUNDING_STATUSES.AVAILABLE
                                ? () => handleCardClick(project)
                                : null
                            }
                            status={project.Status}
                            style={[
                              classes.pc,
                              project.Status !== FUNDING_STATUSES.AVAILABLE
                                ? classes.cursorNotAllowed
                                : '',
                            ].join(' ')}
                          />
                        )
                      } else if (
                        project.Type === PROJECT_TYPES.DISTRIBUTION.type &&
                        activeProjectType === PROJECT_TYPES.DISTRIBUTION
                      ) {
                        return (
                          <DistributionCard
                            cardData={project}
                            id={project.Id}
                            key={project.Id}
                            onClick={
                              project.Status === FUNDING_STATUSES.AVAILABLE
                                ? () => handleCardClick(project)
                                : null
                            }
                            status={project.Status}
                            style={[
                              classes.pc,
                              project.Status !== FUNDING_STATUSES.AVAILABLE
                                ? classes.cursorNotAllowed
                                : '',
                            ].join(' ')}
                          />
                        )
                      }
                      return null
                    })
                  : null}
              </Grid>
            ) : (
              <Box
                className={[
                  classes.colorCharcoalDark,
                  classes.defaultContainer,
                  classes.defaultContainerMain,
                ].join(' ')}
              >
                {projectTotals.projectsCount > 0 ? (
                  <>
                    <Typography variant="h2">
                      {projectTotals.projectsCount} Advance Projects Available
                    </Typography>
                    <Typography
                      className={[
                        classes.textUpper,
                        classes.totalInvestment,
                      ].join(' ')}
                      variant="h3"
                    >
                      Total Investment: {projectTotals.totalInvestment}
                    </Typography>
                  </>
                ) : null}
                <Typography className={[classes.mb1, classes.mt2].join(' ')}>
                  On this page you&apos;ll find two tabs:{' '}
                  <a
                    className="underline"
                    href="/"
                    onClick={(event) => {
                      handleLinkClick(event, 2)
                    }}
                    title="View Translation Projects"
                  >
                    translation projects
                  </a>{' '}
                  and{' '}
                  <a
                    className="underline"
                    href="/"
                    onClick={(event) => {
                      handleLinkClick(event, 1)
                    }}
                    title="View Distribution Projects"
                  >
                    distribution projects
                  </a>
                  . We invite you to take a moment and review the projects. You
                  can click on a project to claim it, and that project (or
                  projects) will be reserved for you. If you have any questions,
                  don&apos;t hesitate to reach out to us.
                </Typography>
                <Typography className={[classes.mb2, classes.mt1].join(' ')}>
                  As you probably know, our goal is to make discipleship
                  resources available to every pastor and leader, regardless of
                  their location, language, or financial position, and the
                  Messenger Cup makes that possible. The resources are now
                  available in over 130 languages, and millions are given to
                  leaders each year. The content can also be found on the
                  MessengerX app and at{' '}
                  <a
                    href="https://messengerx.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    MessengerX.com
                  </a>
                  , which so far has seen activity in 241 countries and over
                  25,400 cities worldwide.
                </Typography>
              </Box>
            )}
          </Fade>
        )}
        <Grid className={[classes.footer].join(' ')} container={true}>
          <footer
            className={[classes.defaultContainer, classes.footerContent].join(
              ' ',
            )}
          >
            <Grid container={true}>
              <Grid item={true} md={1} xs={12}></Grid>
              <Grid item={true} md={4} xs={12}>
                <ul className={[classes.noBullets, classes.ulLiMt05].join(' ')}>
                  <li>
                    <a href="tel:1-800-648-1477">+1 (800) 648-1477</a>
                  </li>
                  <li>
                    <a href="http://messengerinternational.org/contact-us/">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </Grid>
              <Grid item={true} md={3} xs={12}>
                <ul className={[classes.noBullets, classes.ulLiMt05].join(' ')}>
                  <li>
                    <a
                      href="https://www.facebook.com/messengerintl"
                      title="Messenger International on Facebook"
                    >
                      <FacebookIcon className={classes.iconLink} /> Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/messengerintl"
                      title="Messenger International on Twitter"
                    >
                      <TwitterIcon className={classes.iconLink} /> Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/messengerintl"
                      title="Messenger International on Instagram"
                    >
                      <InstagramIcon className={classes.iconLink} /> Instagram
                    </a>
                  </li>
                </ul>
              </Grid>
              <Grid item={true} md={4} xs={12}>
                <ul className={[classes.noBullets, classes.ulLiMt05].join(' ')}>
                  <li>&copy; {today.getFullYear()} Messenger International</li>
                </ul>
              </Grid>
            </Grid>
          </footer>
        </Grid>
        <Elements stripe={stripePromise}>
          <CheckoutDialog
            cancelLabel={cart && cart.length ? 'Cancel' : 'Close'}
            cart={cart}
            confirmLabel="Submit"
            countdownDisplay={
              cart.length ? (
                <Countdown
                  autoStart={true}
                  date={timeRemaining}
                  onComplete={handleCountdownComplete}
                  ref={checkoutCountdownRef}
                  renderer={countdownRenderer}
                  zeroPadTime={4}
                />
              ) : null
            }
            onCancelClick={() => {
              setCheckoutDialogOpen(false)
            }}
            onCheckoutError={(dialogData) => {
              setConfirmationDialogData(
                dialogData || CONFIRMATION_DIALOG_DATA.checkoutError,
              )
              setConfirmationDialogOpen(true)
            }}
            onCheckoutSuccess={(cartData, userData) => {
              sendConfirmationEmail(cartData, userData)
              resetApp()
              setConfirmationDialogData(
                CONFIRMATION_DIALOG_DATA.checkoutSuccess,
              )
              setConfirmationDialogOpen(true)
            }}
            onClose={() => {
              setCheckoutDialogOpen(false)
            }}
            onRemoveProject={handleCheckoutRemoveProject}
            open={checkoutDialogOpen}
            stripePromise={stripePromise}
          />
        </Elements>
        <ConfirmationDialog
          cancelLabel={confirmationDialogData.cancelLabel}
          confirmLabel={confirmationDialogData.confirmLabel}
          message={confirmationDialogData.message}
          onCancelClick={() => {
            setConfirmationDialogOpen(false)
          }}
          onClose={() => {
            setConfirmationDialogOpen(false)
          }}
          onConfirmClick={handleConfirmDialogConfirmClick}
          open={confirmationDialogOpen}
          title={confirmationDialogData.title}
        />
      </Container>
    </ThemeProvider>
  )
}

export default App
