/* eslint-disable no-unused-vars */
export class Project {
  /**
   * Create a Project.
   *
   * @param {object} params - The Project params object.
   * @param {string} params.ClaimedAt - The date on which the ProjectPayment was claimed.
   * @param {string} params.Country - The Project country.
   * @param {string} params.Id - The Project id.
   * @param {string} params.Image - The Project image.
   * @param {string} params.Impact - The Project impact.
   * @param {string} params.Investment - The Project investment.
   * @param {string} params.Language - The Project language.
   * @param {string} params.Name - The Project name.
   * @param {string} params.Quantity - The Project quantity.
   * @param {string} params.Status - The Project status.
   * @param {string} params.Type - The Project type.
   */
  constructor({
    ClaimedAt,
    Country,
    Id,
    Image,
    Impact,
    Investment,
    Language,
    Name,
    Quantity,
    Status,
    Type,
  }) {
    this.ClaimedAt = ClaimedAt
    this.Country = Country
    this.Id = Id
    this.Image = Image
    this.Impact = Impact
    this.ImpactLabel = `${Impact} people`
    this.Investment = Investment
    this.Language = Language
    this.Name = Name
    this.Quantity = Quantity
    this.QuantityLabel = `${Quantity} leaders`
    this.Status = Status
    this.Type = Type

    this.amount = parseFloat(this.Investment.replace(/[^0-9]/g, ''))
  }
}

export class ProjectPayment {
  /**
   * Create a ProjectPayment.
   *
   * @param {object} params - The ProjectPayment params object.
   * @param {string} params.Address - The address attributed to the ProjectPayment.
   * @param {string} params.ClaimedAt - The date on which the ProjectPayment was claimed.
   * @param {string} params.ClaimedByCompany - The company name.
   * @param {string} params.ClaimedByName - The user name.
   * @param {string} params.Email - The user email.
   * @param {array} params.id - The project ids array.
   * @param {string} params.PaymentAmount - The payment amount.
   * @param {string} params.PaymentName - The payment name.
   * @param {string} params.PaymentOn - The date on which the ProjectPayment was made.
   * @param {string} params.PaymentReference - The ProjectPayment reference.
   * @param {string} params.PaymentType - The ProjectPayment type.
   * @param {string} params.Phone - The user phone number.
   */
  constructor({
    Address,
    ClaimedAt,
    ClaimedByCompany,
    ClaimedByName,
    Email,
    id,
    PaymentAmount,
    PaymentName,
    PaymentOn,
    PaymentReference,
    PaymentType,
    Phone,
  }) {
    this.Address = Address
    this.ClaimedAt = ClaimedAt
    this.ClaimedByName = ClaimedByName
    this.Company = ClaimedByCompany
    this.Email = Email
    this.id = id
    this.PaymentAmount = PaymentAmount
    this.PaymentName = PaymentName
    this.PaymentOn = PaymentOn
    this.PaymentReference = PaymentReference
    this.PaymentType = PaymentType
    this.Phone = Phone
  }
}

export class SheetsList {
  /**
   * Create a new SheetsList.
   *
   * @param {object} params - The SheetsList params object.
   * @param {object} params.Distribution - The Distributions object.
   * @param {object} params.Translations - The Translations object.
   */
  constructor({ Distribution, Translation }) {
    this.Distribution = Distribution
    this.Translation = Translation
  }
}
