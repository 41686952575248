export const API_URLS = {
  sendConfirmationEmail: 'email/send_confirm',
  stripePaymentIntent: 'create-payment-intent',
}

/**
 * Trigger a POST call using fetch and the specified data to the specified base urlPath and url.
 *
 * @param {object} params - The function params object.
 * @param {object} params.data - The data object to send.
 * @param {string} params.url - The url pathname to use relative to the `api` directory.
 * @param {string} params.urlPath - The base url path for the API endpoint call.
 *
 * @returns {object} - An object with callback keys and values for `status` and `success`.
 *
 * @throws {Error}
 *
 */
export async function postAPIData({ data, url, urlPath }) {
  let errorReason
  if (!data) {
    errorReason = 'No data object provided.'
  } else if (!url) {
    errorReason = 'No url string provided.'
  } else if (!urlPath) {
    errorReason = 'No urlPath provided.'
  }
  if (errorReason) {
    throw new Error(`Unable to post API data: ${errorReason}`)
  }

  const fetchUrl = `${urlPath}api/${url}`
  const requestOptions = {
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    method: 'POST',
  }

  try {
    const response = await fetch(fetchUrl, requestOptions)

    if (parseInt(response.status) !== 200) {
      throw new Error(
        `Unsuccessful post API data to '${url}': ${response.statusText}`,
      )
    }
    const responseJson = await response.json()
    return {
      payload: responseJson.payload || null,
      status: response.status,
      response: response,
      success: '1',
    }
  } catch (error) {
    return {
      payload: error,
      status: 400,
      response: error,
      success: '0',
    }
  }
}
