import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import { COLORS } from '../../utils/constants'
import { MessengerCupEmblem } from '../icons'

const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 1rem 1rem',
    },
    backgroundColor: COLORS.CHARCOAL_DARK_HEADER,
    color: COLORS.GREY_5,
    flexGrow: 1,
    justifyContent: 'center',
    padding: '2rem 1rem 1rem',
    position: 'relative',
  },
  headerContent: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '60rem',
      padding: '1rem',
    },
    padding: '0',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
  },
  h1: {
    [theme.breakpoints.up('md')]: {
      fontSize: '5rem',
      lineHeight: '0.9375',
      margin: '4rem 0 3.5rem',
    },
    fontSize: '2.25rem',
    letterSpacing: 1.75,
    lineHeight: '1.25',
    margin: '1.75rem 0 1.5rem',
    textTransform: 'uppercase',
  },
  h2: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: 14,
      lineHeight: '1rem',
    },
    fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important`,
    fontSize: '0.5rem',
    fontWeight: 500,
    letterSpacing: 10,
    lineHeight: '0.5rem',
    textTransform: 'uppercase',
  },
}))

export function Header({ cart, subtitle, title }) {
  const classes = useStyles()

  return (
    <Grid className={classes.header} container={true}>
      <Grid className={classes.headerContent} item={true} xs={12}>
        <MessengerCupEmblem height={82} width={63} />
        {title ? (
          <Typography className={classes.h1} variant="h1">
            {title}
          </Typography>
        ) : null}
        {subtitle ? (
          <Typography className={classes.h2} variant="h2">
            {subtitle}
          </Typography>
        ) : null}
        {cart ? cart : null}
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  cart: PropTypes.node,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

Header.defaultProps = {
  cart: null,
  subtitle: null,
  title: null,
}
