import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  Typography,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  dialogActions: {
    justifyContent: 'center',
  },
  dialogTitle: {
    paddingBottom: '0',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Grow ref={ref} {...props} />
})

export function ConfirmationDialog({
  cancelLabel,
  confirmLabel,
  message,
  onCancelClick,
  onClose,
  onConfirmClick,
  open,
  title,
}) {
  const classes = useStyles()

  return (
    <>
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        id="dialog_confirm"
        maxWidth="sm"
        onClose={onClose}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}
      >
        {title ? (
          <DialogTitle
            className={classes.dialogTitle}
            id="confirmation-dialog-title"
          >
            {title}
          </DialogTitle>
        ) : null}
        <DialogContent dividers={false}>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {cancelLabel ? (
            <Button
              id="btn_confirmation_dialog_cancel"
              onClick={onCancelClick}
              variant="contained"
            >
              {cancelLabel}
            </Button>
          ) : null}
          <Button
            color="primary"
            id="btn_confirmation_dialog_confirm"
            onClick={onConfirmClick}
            variant="contained"
          >
            {confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ConfirmationDialog.propTypes = {
  cancelClassName: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmClassName: PropTypes.string,
  confirmLabel: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func,
  onClose: PropTypes.func,
  onConfirmClick: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
}

ConfirmationDialog.defaultProps = {
  cancelClassName: 'cta small transparent',
  cancelLabel: null,
  confirmClassName: 'cta small',
  onCancelClick: () => {},
  onClose: () => {},
  onConfirmClick: () => {},
  open: false,
  title: null,
}
