import React from 'react'
import { Project } from '../classes'
import { ADVANCED_AUCTION_GOOGLE_SHEET, PROJECT_TYPES } from './constants'

/**
 * @typedef GoogleSpreadsheet
 *
 * @param {string} autoRecalc - The recalculation interval option.
 * @param {object} defaultFormat - The format of a cell.
 * @param {object} iterativeCalculationSettings - The circular dependency resolution calculation value.
 * @param {string} locale - The document locale/language (ISO code: e.g. 'en', 'es', 'it').
 * @param {number} sheetCount - Count of child worksheets.
 * @param {object} sheetsById - Child worksheets, keyed by their `sheetId`.
 * @param {Array} sheetsByIndex - Array of worksheets, ordered by their index.
 * @param {object} sheetsByTitle - Child worksheets, keyed by their `title` (beware of title conflicts).
 * @param {string} spreadsheetId - The document id.
 * @param {object} spreadsheetTheme - The spreadsheet theme object.
 * @param {string} timeZone - The document timezone (CLDR format: e.g. 'America/New York', 'GMT-07:00').
 * @param {string} title - The document title.
 *
 * @see {@link https://theoephraim.github.io/node-google-spreadsheet/#/classes/google-spreadsheet}.
 */

/**
 * Convenience function to calculate total cart amount.
 *
 * @param {Array} cart - The cart array of Projects.
 *
 * @returns {number} The float total of all cart projects.
 */
export function calculateTotalCartAmount(cart) {
  let total = 0
  cart.forEach((project) => {
    total = total + project.amount
  })
  return total
}

/**
 * Format the specified amount with commas.
 *
 * @param {number|string} amount - The string amount to format.
 * @returns {string} - The formatted amount.
 */
export function formatWithCommas(value) {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/**
 * Format the specified amount in USD format.
 *
 * @param {number|string} amount - The string amount to format.
 * @returns {number} - The formatted amount.
 */
export function formatUSD(amount) {
  return formatWithCommas(Number(amount).toFixed(2))
}

/**
 * @typedef SerializationOptions
 *
 * @param {Function} serialize - The serialization function (default: JSON.stringify).
 * @param {Function} deserialize - The deserialization function (default: JSON.parse).
 */

/**
 * Retrieve the project type by its `id` attribute.
 *
 * @param {number} id - The project id.
 *
 * @returns {object} The project object.
 */
export function getProjectType(id) {
  return Object.values(PROJECT_TYPES).find((p) => {
    return p.id === id
  })
}

/**
 * Parse and return normalized data from specified raw Google Sheets data.
 *
 * @param {GoogleSpreadsheet} sheet - The sheet retrieved from Google Sheets.
 *
 * @returns {object<Project>} Object of Project objects.
 */
export async function parseGoogleSheetData(sheet) {
  // Simplify sheets data structure.
  const sheets = {
    listA: sheet.sheetsById[ADVANCED_AUCTION_GOOGLE_SHEET.sheetIndices.listA],
    listB: sheet.sheetsById[ADVANCED_AUCTION_GOOGLE_SHEET.sheetIndices.listB],
  }

  // Create up return data object.
  const rtnData = {}

  // Iterate over sheets, normalize data structure to our structure preference.
  for (const [sheetName, sheetData] of Object.entries(sheets)) {
    const key = sheetName.toLowerCase().replace(/ /g, '_')

    // Make return data key/object for each sheet.
    rtnData[key] = {}
    const rows = await sheetData.getRows()

    // Iterate over elements/rows and populate items into type-specific objects.
    rows.map((row) => {
      const id = row.ID || null
      const type = row.Type || ''
      const project = new Project({
        ClaimedAt: row.ClaimedAt,
        Country: row.Country,
        Id: id,
        Image: row.Image,
        Impact: row.Impact,
        Investment: row.Investment,
        Language: row.Language,
        Name: row.Name,
        Quantity: row.Quantity,
        Status: row.Status,
        Type: type,
      })
      if (id !== null) {
        rtnData[key][id] = project
      }
    })
  }
  return rtnData
}

/**
 * Convenience function to use localStorage for state-based logic.
 *
 * @param {string} key - The key of the localStorage item.
 * @param {*} defaultValue - The default value of the item to store.
 * @param {SerializationOptions} serializationOptions - Object of options.
 *
 * @returns {Array} - React state array.
 */
export function useLocalStorageState(
  key,
  defaultValue = '',
  { serialize = JSON.stringify, deserialize = JSON.parse } = {},
) {
  const [state, setState] = React.useState(() => {
    const valueInLocalStorage = window.localStorage.getItem(key)
    if (valueInLocalStorage) {
      try {
        return deserialize(valueInLocalStorage)
      } catch (error) {
        window.localStorage.removeItem(key)
      }
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue
  })

  const prevKeyRef = React.useRef(key)

  React.useEffect(() => {
    const prevKey = prevKeyRef.current
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey)
    }
    prevKeyRef.current = key
    window.localStorage.setItem(key, serialize(state))
  }, [key, serialize, state])

  return [state, setState]
}
