import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Grow,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core'
import TimerIcon from '@material-ui/icons/Timer'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  CA_PROVINCES,
  COLORS,
  CONFIRMATION_DIALOG_DATA,
  DEFAULT_USER_INFO,
  FORM_SUBMIT_STATUSES,
  IS_LIVE,
  PAYMENT_TYPES,
  PROJECT_TYPES,
  US_STATES,
} from '../../utils/constants'
import { calculateTotalCartAmount } from '../../utils/helpers'
import { API_URLS, postAPIData } from '../../utils/api'
import { ProjectPayment } from '../../classes'
import { VALIDATOR_TYPES, validator } from '../../utils/validators'
import { triggerWebhook, WEBHOOK_URLS } from '../../utils/webhooks'
import { DistributionIcon, TranslationIcon } from '../icons'
import { CartCard } from '../project-card'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: '1rem',
    },
  },
  countdownBox: {
    [theme.breakpoints.up('md')]: {
      right: '1.5rem',
      top: '1.125rem',
      width: 'auto',
    },
    alignItems: 'center',
    background: COLORS.ORANGE_10,
    borderColor: COLORS.ORANGE_50,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
    borderWidth: '1px',
    justifyContent: 'center',
    margin: '0',
    maxWidth: '100%',
    padding: '1rem',
    position: 'absolute',
    top: '0',
    width: '100%',
  },
  countdownBoxText: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '1rem',
    },
    margin: '0',
  },
  dialogActions: {
    justifyContent: 'center',
  },
  dialogCartHeader: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: '600',
  },
  dialogContent: {
    margin: '0',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    paddingTop: '0',
    '&:first-child': {
      paddingTop: '0',
    },
  },
  dialogTitle: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '3rem',
    },
    paddingBottom: '0',
  },
  formControl: {
    [theme.breakpoints.up('md')]: {
      minWidth: `calc(50% - 2rem)`,
      width: 'unset',
    },
    margin: '1rem',
    minWidth: `calc(100% - 2rem)`,
    width: 'calc(100% - 2rem)',
  },
  full: {
    minWidth: 'calc(100% - 2rem)',
  },
  m0a: {
    margin: '0 auto !important',
  },
  mb0: {
    marginBottom: '0 !important',
  },
  mb1: {
    marginBottom: '1rem !important',
  },
  mb2: {
    marginBottom: '2rem !important',
  },
  mb3: {
    marginBottom: '3rem !important',
  },
  mdUp: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mt0: {
    marginTop: '0 !important',
  },
  mt1: {
    marginTop: '1rem !important',
  },
  mt2: {
    marginTop: '2rem !important',
  },
  mt3: {
    marginTop: '3rem !important',
  },
  pc: {
    border: '1px solid #ccc',
    color: COLORS.CHARCOAL_DARK_NAV_TABS,
    margin: '0.5rem 1rem',
    width: 'calc(100% - 2rem - 2px)',
  },
  quarter: {
    minWidth: `calc(25% - 2rem)`,
  },
  relative: {
    position: 'relative',
  },
  smOnly: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  stripeWrapper: {
    margin: '0 0 3rem 0',
  },
  third: {
    minWidth: `calc(33% - 2rem)`,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Grow ref={ref} {...props} />
})

const useOptions = () => {
  const options = React.useMemo(
    () => ({
      classes: {
        base: 'MuiInputBase-Input',
        invalid: 'Mui-error',
      },
      hideIcon: true,
      hidePostalCode: true,
      style: {
        base: {
          fontFamily: `'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
          fontSize: '16px',
          fontWeight: '400',
          padding: '6px 0 7px',
          '::placeholder': {
            color: '#a0a0a0',
          },
        },
        invalid: {
          color: '#f44336',
        },
      },
    }),
    [],
  )
  return options
}

export function CheckoutDialog({
  cancelLabel,
  cart,
  confirmLabel,
  countdownDisplay,
  onCancelClick,
  onCheckoutError,
  onCheckoutSuccess,
  onClose,
  onRemoveProject,
  open,
}) {
  const classes = useStyles()
  const [formErrors, setFormErrors] = React.useState({})
  const [formSubmitStatus, setFormSubmitStatus] = React.useState(
    FORM_SUBMIT_STATUSES.IDLE,
  )
  const [userInfo, setUserInfo] = React.useState(DEFAULT_USER_INFO)

  const elements = useElements()
  const options = useOptions()
  const stripe = useStripe()

  const today = new Date()
  const paymentDueDate = `December 1, ${today.getFullYear()}`

  function handleRemoveClick(_event, projectData) {
    onRemoveProject({ projectData })
  }

  async function onFormSubmit(event) {
    event.preventDefault()

    // If already submitted (pending status), simply return.
    if (formSubmitStatus === FORM_SUBMIT_STATUSES.PENDING) {
      return
    }

    setFormSubmitStatus(FORM_SUBMIT_STATUSES.PENDING)

    // Set validator type for zip/postal code.
    let zipPostalValidatorType = CA_PROVINCES[userInfo.stateProvince]
      ? 'caPostalCode'
      : 'usZipCode'

    // Set form errors with validation results.
    const errors = {
      fullName:
        userInfo.fullName && userInfo.fullName.length >= 2
          ? ''
          : 'Enter a valid name',
      address1:
        userInfo.address1 && userInfo.address1.length >= 4
          ? ''
          : 'Enter a valid address',
      cardNumber: '',
      city:
        userInfo.city && userInfo.city.length >= 2 ? '' : 'Enter a valid city',
      stateProvince:
        userInfo.stateProvince && userInfo.stateProvince.length === 2
          ? ''
          : 'Enter a valid state/province',
      zipPostal: validator({
        type: VALIDATOR_TYPES[zipPostalValidatorType],
        value: userInfo.zipPostal,
      })
        ? ''
        : 'Enter a valid zip/postal code',
      email: validator({
        type: VALIDATOR_TYPES.email,
        value: userInfo.email,
      })
        ? ''
        : 'Enter a valid email',
      phone: validator({
        type: VALIDATOR_TYPES.phone,
        value: userInfo.phone,
      })
        ? ''
        : 'Enter a valid phone number',
    }
    setFormErrors(errors)

    // If there are any form errors, return and don't send form data.
    if (
      Object.values(errors).filter((error) => {
        return error !== ''
      }).length
    ) {
      setFormSubmitStatus(FORM_SUBMIT_STATUSES.IDLE)
      return
    }

    const totalAmount = calculateTotalCartAmount(cart)
    let clientSecret = ''
    let paymentIntentObject

    if (userInfo.paymentType === PAYMENT_TYPES.PAY_NOW) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Disable form submission until loaded.
        return
      }
      const cardElement = elements.getElement(CardElement)

      try {
        const apiResponse = await postAPIData({
          data: {
            // Convert amount to cents.
            amount: totalAmount * 100,
          },
          url: API_URLS.stripePaymentIntent,
          urlPath: !IS_LIVE
            ? `${window.location.href.replace('/hr1', '').replace('/hr2', '')}`
            : `${window.location.origin}/`,
        })
        if (
          apiResponse.success === '1' &&
          apiResponse.payload &&
          apiResponse.payload['client_secret'] &&
          apiResponse.payload['id']
        ) {
          // API Success. Can succeed with the rest of the submission.
          clientSecret = apiResponse.payload['client_secret']
          const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardElement,
              billing_details: {
                address: {
                  city: userInfo.city,
                  line1: userInfo.address1,
                  line2: userInfo.address2 || '',
                  postal_code: userInfo.zipPostal,
                  state: userInfo.stateProvince,
                },
                email: userInfo.email,
                name: userInfo.fullName,
              },
            },
          })
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds).
            // Test card: 4000 0000 0000 9995
            console.error(result.error.message)
            onCheckoutError({
              ...CONFIRMATION_DIALOG_DATA.creditCardError,
              message: `There was a problem processing your credit card with the specified information: ${result.error.message}`,
            })
            setFormErrors((prevErrors) => {
              return {
                ...prevErrors,
                cardNumber: result.error.message,
              }
            })
            setFormSubmitStatus(FORM_SUBMIT_STATUSES.IDLE)
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              paymentIntentObject = result.paymentIntent
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions.
              continueProcessing()
            }
          }
        } else {
          console.error('API Response Error: Checkout Dialog')
          console.error(apiResponse)
          onCheckoutError(CONFIRMATION_DIALOG_DATA.checkoutError)
          setFormSubmitStatus(FORM_SUBMIT_STATUSES.IDLE)
        }
      } catch (apiError) {
        console.error('API Error: Checkout Dialog')
        console.error(apiError)
        onCheckoutError(CONFIRMATION_DIALOG_DATA.checkoutError)
        setFormSubmitStatus(FORM_SUBMIT_STATUSES.IDLE)
      }
    } else {
      continueProcessing()
    }

    async function continueProcessing() {
      // Create ProjectPayment object to send as webhook data.
      const cartIds = []
      cart.map((cartItem) => {
        cartIds.push(cartItem.Id)
      })

      const now = new Date()
      const projectPayment = new ProjectPayment({
        Address: String(
          `${userInfo.address1} ${userInfo.address2} ${userInfo.city}, ${userInfo.stateProvince} ${userInfo.zipPostal}`,
        ).trim(),
        ClaimedAt: now.toISOString(),
        ClaimedByCompany: userInfo.company,
        ClaimedByName: userInfo.fullName,
        Email: userInfo.email,
        id: cartIds,
        PaymentAmount: clientSecret !== '' && totalAmount ? totalAmount : '', // Not needed until Stripe integration.
        PaymentName: clientSecret !== '' ? userInfo.fullName : '', // Not needed until Stripe integration.
        PaymentOn: now.toISOString(),
        PaymentReference:
          clientSecret !== '' && paymentIntentObject
            ? paymentIntentObject.id
            : '',
        PaymentType: userInfo.paymentType,
        Phone: userInfo.phone,
      })

      // Send projectPayment data to webhook.
      try {
        const webhookResponse = await triggerWebhook({
          data: projectPayment,
          url: WEBHOOK_URLS.submitProjectPayment,
        })
        if (webhookResponse.status === 'success') {
          onCheckoutSuccess(cart, userInfo)
          setFormSubmitStatus(FORM_SUBMIT_STATUSES.IDLE)
          setUserInfo(DEFAULT_USER_INFO)
        } else {
          console.error(webhookResponse)
          onCheckoutError()
        }
      } catch (webhookError) {
        console.error('Webhook Error')
        console.error(webhookError)
        setFormSubmitStatus(FORM_SUBMIT_STATUSES.IDLE)
        onCheckoutError(CONFIRMATION_DIALOG_DATA.checkoutError)
      }
    }
  }

  return (
    <>
      <Dialog
        aria-labelledby="checkout-form-dialog-title"
        className={classes.relative}
        id="dialog_confirm"
        maxWidth="md"
        onClose={onClose}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}
      >
        {cart && cart.length ? (
          <Grid className={classes.countdownBox} container={true} spacing={1}>
            <Grid item={true}>
              <TimerIcon />
            </Grid>
            <Grid item={true}>
              <Typography className={classes.countdownBoxText}>
                Cart items will be
                <span className={classes.smOnly}>
                  <br />
                </span>
                <span className={classes.mdUp}> </span>
                released in: {countdownDisplay ? countdownDisplay : null}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <DialogTitle
          className={classes.dialogTitle}
          id="checkout-form-dialog-title"
        >
          Checkout
        </DialogTitle>
        <form onSubmit={onFormSubmit}>
          <DialogContent
            className={[classes.root, classes.dialogContent].join(' ')}
            dividers={false}
          >
            <Typography className={classes.dialogCartHeader} variant="h3">
              Claimed Projects
            </Typography>
            {cart && cart.length ? (
              cart.map((cartItem, index) => {
                return (
                  <CartCard
                    cardData={cartItem}
                    icon={
                      cartItem.Type === PROJECT_TYPES.TRANSLATION.type ? (
                        <TranslationIcon
                          color={COLORS.BURNT_ORANGE}
                          style={{ height: 'auto', width: '2.5rem' }}
                        />
                      ) : (
                        <DistributionIcon
                          color={COLORS.BURNT_ORANGE}
                          style={{ height: 'auto', width: '2.5rem' }}
                        />
                      )
                    }
                    key={index}
                    onRemoveClick={handleRemoveClick}
                    style={classes.pc}
                  >
                    {cartItem.Name}
                  </CartCard>
                )
              })
            ) : (
              <Typography>You do not have any claimed Projects.</Typography>
            )}
            {cart && cart.length ? (
              <>
                <Typography
                  className={[
                    classes.dialogCartHeader,
                    classes.mt3,
                    classes.mb0,
                  ].join(' ')}
                  variant="h3"
                >
                  Payment Type
                </Typography>
                <FormControl
                  className={[
                    classes.formControl,
                    classes.full,
                    classes.mt0,
                  ].join(' ')}
                >
                  <RadioGroup
                    id="paymentType"
                    name="paymentType"
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return {
                          ...prevState,
                          paymentType: e.target.value,
                        }
                      })
                    }}
                    row={true}
                    value={userInfo.paymentType}
                  >
                    {Object.values(PAYMENT_TYPES).map((paymentType) => {
                      return (
                        <FormControlLabel
                          control={
                            <Radio
                              checked={userInfo.paymentType === paymentType}
                              inputProps={{
                                'aria-label': `'${paymentType}'`,
                              }}
                              key={paymentType}
                              name="paymentType"
                              value={paymentType}
                            />
                          }
                          key={paymentType}
                          label={paymentType}
                          value={paymentType}
                        />
                      )
                    })}
                  </RadioGroup>
                </FormControl>
                {userInfo.paymentType === PAYMENT_TYPES.CHECK ? (
                  <Typography className={[classes.mb1, classes.mt0].join(' ')}>
                    To give by <b>check (preferred method)</b>, please make your
                    check payable to{' '}
                    <i>&lsquo;Messenger International&rsquo;</i> and put{' '}
                    <i>
                      &lsquo;<b>Messenger Cup 2024 - Pre-Fund</b>&rsquo;
                    </i>{' '}
                    in the memo. Mail it to:
                    <br />
                    <i>
                      Messenger International
                      <br />
                      PO Box 888, Palmer Lake, CO 80133
                    </i>
                  </Typography>
                ) : null}
                <Typography className={[classes.mb3, classes.mt0].join(' ')}>
                  <i>
                    If you are paying at a later date, please send your donation
                    by {paymentDueDate}.
                  </i>
                </Typography>
                {userInfo.paymentType === PAYMENT_TYPES.PAY_NOW ? (
                  <>
                    <Typography
                      className={[
                        classes.dialogCartHeader,
                        classes.mt1,
                        classes.mb0,
                      ].join(' ')}
                      variant="h3"
                    >
                      Credit Card
                    </Typography>
                    <div className={classes.stripeWrapper}>
                      <FormControl
                        className={classes.formControl}
                        error={Boolean(formErrors.cardNumber)}
                      >
                        <CardElement
                          className="MuiInputBase-Input"
                          onBlur={() => {
                            // console.log('CardElement [onBlur]', e)
                          }}
                          onChange={() => {
                            // console.log('CardElement [onChange]', e)
                          }}
                          onFocus={() => {
                            // console.log('CardElement [onFocus]', e)
                          }}
                          onReady={() => {
                            // console.log('CardElement [onReady]', e)
                          }}
                          options={options}
                        />
                        <FormHelperText id="card-number-elementErrorText">
                          {formErrors.cardNumber}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </>
                ) : null}
                <Typography
                  className={[
                    classes.dialogCartHeader,
                    classes.mt1,
                    classes.mb0,
                  ].join(' ')}
                  variant="h3"
                >
                  Your Information
                </Typography>
                <FormControl
                  className={classes.formControl}
                  error={Boolean(
                    formErrors.fullName && formErrors.fullName.length,
                  )}
                >
                  <InputLabel htmlFor="fullName">Name</InputLabel>
                  <Input
                    id="fullName"
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return {
                          ...prevState,
                          fullName: e.target.value,
                        }
                      })
                    }}
                    value={userInfo.fullName}
                  />
                  <FormHelperText id="fullNameErrorText">
                    {formErrors.fullName}
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="company">Organization</InputLabel>
                  <Input
                    id="company"
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return {
                          ...prevState,
                          company: e.target.value,
                        }
                      })
                    }}
                    value={userInfo.company}
                  />
                </FormControl>
                <FormControl
                  className={classes.formControl}
                  error={Boolean(
                    formErrors.address1 && formErrors.address1.length,
                  )}
                >
                  <InputLabel htmlFor="address1">Address</InputLabel>
                  <Input
                    id="address1"
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return {
                          ...prevState,
                          address1: e.target.value,
                        }
                      })
                    }}
                    value={userInfo.address1}
                  />
                  <FormHelperText id="address1ErrorText">
                    {formErrors.address1}
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="address2">Address (cont)</InputLabel>
                  <Input
                    id="address2"
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return {
                          ...prevState,
                          address2: e.target.value,
                        }
                      })
                    }}
                    value={userInfo.address2}
                  />
                </FormControl>
                <FormControl
                  className={[classes.formControl, classes.third].join(' ')}
                  error={Boolean(formErrors.city && formErrors.city.length)}
                >
                  <InputLabel htmlFor="city">City</InputLabel>
                  <Input
                    id="city"
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return {
                          ...prevState,
                          city: e.target.value,
                        }
                      })
                    }}
                    value={userInfo.city}
                  />
                  <FormHelperText id="cityErrorText">
                    {formErrors.city}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  className={[classes.formControl, classes.third].join(' ')}
                  error={Boolean(
                    formErrors.stateProvince && formErrors.stateProvince.length,
                  )}
                >
                  <InputLabel htmlFor="stateProvince">
                    State/Province
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="stateProvince"
                    inputProps={{
                      name: 'stateProvince',
                      id: 'stateProvince',
                    }}
                    native={true}
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return {
                          ...prevState,
                          stateProvince: e.target.value,
                        }
                      })
                    }}
                    value={userInfo.state}
                  >
                    <option disabled={true} value=""></option>
                    {US_STATES && Object.keys(US_STATES).length ? (
                      <optgroup label="US States">
                        {Object.values(US_STATES).map((stateValue) => {
                          return (
                            <option
                              key={stateValue.abbreviation}
                              value={stateValue.abbreviation}
                            >
                              {stateValue.name}
                            </option>
                          )
                        })}
                      </optgroup>
                    ) : null}
                    {CA_PROVINCES && Object.keys(CA_PROVINCES).length ? (
                      <optgroup label="CA Provinces/Territories">
                        {Object.values(CA_PROVINCES).map((provinceValue) => {
                          return (
                            <option
                              key={provinceValue.abbreviation}
                              value={provinceValue.abbreviation}
                            >
                              {provinceValue.name}
                            </option>
                          )
                        })}
                      </optgroup>
                    ) : null}
                  </Select>
                  <FormHelperText id="stateProvinceErrorText">
                    {formErrors.stateProvince}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  className={[classes.formControl, classes.third].join(' ')}
                  error={Boolean(
                    formErrors.zipPostal && formErrors.zipPostal.length,
                  )}
                >
                  <InputLabel htmlFor="zipPostal">Zip/Postal Code</InputLabel>
                  <Input
                    id="zipPostal"
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return {
                          ...prevState,
                          zipPostal: e.target.value,
                        }
                      })
                    }}
                    value={userInfo.zipPostal}
                  />
                  <FormHelperText id="zipPostalErrorText">
                    {formErrors.zipPostal}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  className={[classes.formControl].join(' ')}
                  error={Boolean(formErrors.email && formErrors.email.length)}
                >
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <Input
                    id="email"
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return {
                          ...prevState,
                          email: e.target.value,
                        }
                      })
                    }}
                    value={userInfo.email}
                  />
                  <FormHelperText id="emailErrorText">
                    {formErrors.email}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  className={[classes.formControl].join(' ')}
                  error={Boolean(formErrors.phone && formErrors.phone.length)}
                >
                  <InputLabel htmlFor="phone">Phone</InputLabel>
                  <Input
                    id="phone"
                    onChange={(e) => {
                      setUserInfo((prevState) => {
                        return {
                          ...prevState,
                          phone: e.target.value,
                        }
                      })
                    }}
                    value={userInfo.phone}
                  />
                  <FormHelperText id="phoneErrorText">
                    {formErrors.phone}
                  </FormHelperText>
                </FormControl>
              </>
            ) : null}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {cancelLabel ? (
              <Button
                disabled={formSubmitStatus === FORM_SUBMIT_STATUSES.PENDING}
                id="btn_confirmation_dialog_cancel"
                onClick={onCancelClick}
                variant="contained"
              >
                {cancelLabel}
              </Button>
            ) : null}
            {cart && cart.length ? (
              <Button
                color="primary"
                disabled={formSubmitStatus === FORM_SUBMIT_STATUSES.PENDING}
                id="btn_confirmation_dialog_confirm"
                type="submit"
                variant="contained"
              >
                {confirmLabel}
              </Button>
            ) : null}
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

CheckoutDialog.propTypes = {
  cancelLabel: PropTypes.string,
  cart: PropTypes.array,
  confirmLabel: PropTypes.string.isRequired,
  countdownDisplay: PropTypes.element,
  onCancelClick: PropTypes.func,
  onCheckoutError: PropTypes.func,
  onCheckoutSuccess: PropTypes.func,
  onClose: PropTypes.func,
  onRemoveProject: PropTypes.func,
  open: PropTypes.bool,
}

CheckoutDialog.defaultProps = {
  cancelLabel: null,
  cart: [],
  countdownDisplay: null,
  onCancelClick: () => {},
  onCheckoutError: () => {},
  onCheckoutSuccess: () => {},
  onClose: () => {},
  onRemoveProject: () => {},
  open: false,
}
