import React from 'react'
import PropTypes from 'prop-types'

export function TranslationIcon({ color, style }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 92.06 100"
        fill="none"
        role="img"
        style={style}
      >
        <title>Translation Icon</title>
        <path
          d="M61.5,15.1h3.8V24a1.5,1.5,0,0,0,3,0V13.6a1.54,1.54,0,0,0-1.5-1.5H61.5A1.54,1.54,0,0,0,60,13.6,1.47,1.47,0,0,0,61.5,15.1Z"
          fill={color}
        />
        <path
          d="M75.5,63.4H38.3a1.5,1.5,0,1,0,0,3H75.4a1.54,1.54,0,0,0,1.5-1.5A1.45,1.45,0,0,0,75.5,63.4Z"
          fill={color}
        />
        <path
          d="M75.5,69.1H38.3a1.5,1.5,0,0,0,0,3H75.4a1.54,1.54,0,0,0,1.5-1.5A1.45,1.45,0,0,0,75.5,69.1Z"
          fill={color}
        />
        <path
          d="M75.5,74.7H38.3a1.5,1.5,0,0,0,0,3H75.4a1.54,1.54,0,0,0,1.5-1.5A1.45,1.45,0,0,0,75.5,74.7Z"
          fill={color}
        />
        <path
          d="M62.9,80.4H38.3a1.5,1.5,0,0,0,0,3H62.8a1.54,1.54,0,0,0,1.5-1.5A1.45,1.45,0,0,0,62.9,80.4Z"
          fill={color}
        />
        <path
          d="M91.6,25.7a1.45,1.45,0,0,0-2.1,0l-2.4,2.4a9.67,9.67,0,0,0-16.3,2h-15V4a4,4,0,0,0-4-4H4A4,4,0,0,0,0,4V67.2a4,4,0,0,0,4,4H28.9V86.4a2.1,2.1,0,0,0,.4,1.1h0L41.4,99.6h0c.1.1.3.2.4.3h.1c.1,0,.3.1.4.1H80.7A4.27,4.27,0,0,0,85,95.7V42.4a9.73,9.73,0,0,0,4.4-8.1,8.42,8.42,0,0,0-.7-3.5l3-3A1.55,1.55,0,0,0,91.6,25.7ZM4,68.2a1,1,0,0,1-1-1V4A1,1,0,0,1,4,3H51.9a1,1,0,0,1,1,1V30.1H33.2a4.35,4.35,0,0,0-4.3,4H9.3a1.5,1.5,0,0,0,0,3H28.9v2.7H9.3a1.5,1.5,0,0,0,0,3H28.9v2.7H9.3a1.5,1.5,0,0,0,0,3H28.9v2.7H9.3a1.5,1.5,0,0,0,0,3H28.9V68.3H4ZM39.7,87.9h0A1.32,1.32,0,0,1,41,89.2h0v5.7l-6.9-7Zm42.2,7.8A1.32,1.32,0,0,1,80.6,97H43.9V89.2a4.27,4.27,0,0,0-4.3-4.3H31.8V34.4a1.32,1.32,0,0,1,1.3-1.3H69.9c0,.4-.1.8-.1,1.2A9.71,9.71,0,0,0,79.5,44a9.86,9.86,0,0,0,2.4-.3Zm4.4-61.4a6.7,6.7,0,1,1-6.7-6.7A6.82,6.82,0,0,1,85,30.3l-4.8,4.8-2.6-2.6a1.48,1.48,0,1,0-2.1,2.1l3.7,3.7a1.5,1.5,0,0,0,1.1.4,2.1,2.1,0,0,0,1.1-.4l5-5A3.08,3.08,0,0,0,86.3,34.3Z"
          fill={color}
        />
        <path
          d="M53.3,54.2h7.1l1.4,4.2h4.3L59,39.4H54.7l-7.2,19h4.2Zm3.6-10.1h0l2.4,7H54.4Z"
          fill={color}
        />
        <path
          d="M19.4,22.9A2.89,2.89,0,0,0,22.5,26a9.38,9.38,0,0,0,5.4-2.2A23.6,23.6,0,0,0,31.6,19a2.65,2.65,0,0,1,1.7,2.4c0,2.1-2.1,3.4-5.3,3.7l1.6,2.2c5.1-.7,6.9-2.8,6.9-6a5.1,5.1,0,0,0-3.8-5,1.42,1.42,0,0,1,.2-.6L30,15.2c0,.3-.1.3-.1.6a9,9,0,0,0-2.6.2V13.5c2.4-.1,4.8-.3,7-.6L34,10a39.54,39.54,0,0,1-6.6.8,21.33,21.33,0,0,0,.1-2.2l-3.1-.2c0,.8-.1,1.7-.1,2.5H20.5l.1,2.8h.3c.8,0,2.1,0,3.3-.1v3.2C21.4,18,19.4,20.2,19.4,22.9Zm9.3-4.5a8.55,8.55,0,0,1-1.5,2.2c-.1-.7-.1-1.3-.2-2C27.3,18.6,28.2,18.4,28.7,18.4Zm-4.4,1.2a26.07,26.07,0,0,0,.4,3.1,2.82,2.82,0,0,1-1.5.4c-1,0-1-.6-1-.9A3.73,3.73,0,0,1,24.3,19.6Z"
          fill={color}
        />
      </svg>
    </>
  )
}

TranslationIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
}

TranslationIcon.defaultProps = {
  color: '#000000',
  style: { position: 'relative' },
}
