/**
 * @module MessengerCupEmblem
 */
import React from 'react'
import PropTypes from 'prop-types'
import Emblem from '../../assets/images/emblem_grey_5.png'

/**
 * Represents the Messenger Cup Emblem.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.height] - Optional height value (Default: 539).
 * @param {string} [props.style] - Optional style object to apply to the logo.
 * @param {string} [props.width] - Optional width value (Default: 1184).
 *
 * @returns {React.ReactComponent} - The MessengerCupEmblem component.
 */
export function MessengerCupEmblem({
  height,
  style,
  width,
  ...passThroughProps
}) {
  return (
    <img
      alt="Messenger Cup Logo"
      height={height}
      src={Emblem}
      style={style}
      width={width}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...passThroughProps}
    />
  )
}

MessengerCupEmblem.propTypes = {
  height: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.string),
  width: PropTypes.number,
}

MessengerCupEmblem.defaultProps = {
  height: 252,
  style: { position: 'relative' },
  width: 330,
}
