import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import { COLORS } from '../../utils/constants'
import { Project } from '../../classes'
import { TranslationIcon } from '../icons'
import { ProjectCard } from './index'

const useStyles = makeStyles((theme) => ({
  m0: {
    margin: '0',
  },
  pcLabel: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
      marginBottom: '0.5rem',
    },
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    padding: '0 0.25rem',
    textAlign: 'right',
  },
  pcValue: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
      marginBottom: '0.5rem',
    },
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    padding: '0 0.25rem',
    textAlign: 'left',
  },
}))

export function TranslationCard({
  cardData,
  onClick,
  status,
  style,
  ...props
}) {
  const classes = useStyles()

  return (
    <ProjectCard
      details={
        <>
          <Grid item={true} md={4} xs={5}>
            <Typography
              className={[classes.m0, classes.pcLabel].join(' ')}
              variant="body1"
            >
              Language:
            </Typography>
          </Grid>
          <Grid item={true} md={8} xs={7}>
            <Typography
              className={[classes.m0, classes.pcValue].join(' ')}
              variant="body1"
            >
              {cardData.Language ? cardData.Language : ''}
            </Typography>
          </Grid>
          <Grid item={true} md={4} xs={5}>
            <Typography
              className={[classes.m0, classes.pcLabel].join(' ')}
              variant="body1"
            >
              Country:
            </Typography>
          </Grid>
          <Grid item={true} md={8} xs={7}>
            <Typography
              className={[classes.m0, classes.pcValue].join(' ')}
              variant="body1"
            >
              {cardData.Country ? cardData.Country : ''}
            </Typography>
          </Grid>
          <Grid item={true} md={4} xs={5}>
            <Typography
              className={[classes.m0, classes.pcLabel].join(' ')}
              variant="body1"
            >
              Investment:
            </Typography>
          </Grid>
          <Grid item={true} md={8} xs={7}>
            <Typography
              className={[classes.m0, classes.pcValue].join(' ')}
              variant="body1"
            >
              {cardData.Investment ? cardData.Investment : ''}
            </Typography>
          </Grid>
        </>
      }
      icon={
        <TranslationIcon
          color={COLORS.BURNT_ORANGE}
          style={{ height: 'auto', width: '2.5rem' }}
        />
      }
      onClick={onClick}
      status={status}
      style={style}
      title={cardData.Name}
      {...props}
    />
  )
}

TranslationCard.propTypes = {
  cardData: PropTypes.shape(Project).isRequired,
  onClick: PropTypes.func,
  status: PropTypes.string,
  style: PropTypes.string,
}

TranslationCard.defaultProps = {
  onClick: () => {},
  style: '',
}
