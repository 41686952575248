import React from 'react'
import { DistributionIcon, TranslationIcon } from '../components/icons'

export const IS_LIVE = true

// Values for these ENV vars can be found in Messenger International's
// 1Password vault for Zapier.
export const ADVANCED_AUCTION_GOOGLE_SHEET = {
  clientEmail: process.env.REACT_APP_GCP_PROJECT_EMAIL,
  privateKey: process.env.REACT_APP_GCP_PROJECT_PRIVATE_KEY.replace(
    /\\n/g,
    '\n',
  ),
  sheetId: '1BQAmqm55jYRx8nWfc1apchFE_zzjB6kfTMTukRLr8kE',
  sheetIndices: {
    listA: '1777571274',
    listB: '0',
  },
  url: 'https://docs.google.com/spreadsheets/d/1BQAmqm55jYRx8nWfc1apchFE_zzjB6kfTMTukRLr8kE/edit#gid=1777571274/pubhtml',
}

export const CART_TIMEOUT_DURATION = 600000 // 10 minutes in milliseconds
export const DATA_RETRIEVAL_TIME_DURATION = 20000 // 20 seconds in milliseconds

export const COLORS = {
  BLACK: '#000000',
  BLACK_25: 'rgba(0, 0, 0, 0.25)',
  BURNT_ORANGE: '#9f6338',
  BURNT_ORANGE_LINK: '#a38c71',
  CHARCOAL_DARK: '#3e3e3e',
  CHARCOAL_DARK_HEADER: '#1a1a19',
  CHARCOAL_DARK_NAV_TABS: '#1a1a19',
  CHARCOAL_MED: '#5e5e5e',
  CREAM: 'rgba(244, 239, 230, 1)',
  FOREST: 'rgba(57, 78, 71, 1)',
  FOREST_NAV_TABS: 'rgba(65, 88, 80, 1)',
  GREEN: '#60A85B',
  GREY_5: '#fdfcfb',
  GREY_76: '#767676',
  NAV_BAR_BLACK: 'rgba(28, 28, 28, 1)',
  NAV_BAR_BROWN: 'rgba(235, 225, 213, 1)',
  NAV_BAR_GREY: '#e4e0dc',
  NAV_TABS_INDICATOR_COLOR: '#1a1a19',
  ORANGE: 'rgba(241, 85, 44)',
  ORANGE_10: 'rgba(254, 239, 236)',
  ORANGE_20: 'rgba(241, 85, 44, 0.2)',
  ORANGE_50: 'rgba(241, 85, 44, 0.5)',
  WHITE: '#FFFFFF',
}

export const ERROR_MESSAGES = {
  ADD_PROJECT_WEBHOOK:
    'There was an error adding the project to your cart. Please try again.',
  CHECKOUT_FORM_WEBHOOK:
    'There was an error processing your checkout. Please try again.',
  REMOVE_PROJECT_WEBHOOK:
    'There was an error removing the project from your cart. Please try again.',
}

export const CONFIRMATION_DIALOG_DATA = {
  addToCart: {
    cancelLabel: 'No',
    confirmLabel: 'Yes',
    message: 'Are you sure you want to add this project to your cart?',
  },
  addToCartError: {
    confirmLabel: 'OK',
    message: ERROR_MESSAGES.ADD_PROJECT_WEBHOOK,
    title: 'Cart Error',
  },
  checkoutError: {
    confirmLabel: 'OK',
    message: ERROR_MESSAGES.CHECKOUT_FORM_WEBHOOK,
    title: 'Checkout Error',
  },
  checkoutSuccess: {
    confirmLabel: 'Close',
    message: `We wish you could've made it to the Cup, but we're so grateful that you're still helping us make discipleship resources available to everyone, everywhere.`,
    title: 'Thank you for your gift!',
  },
  checkoutTimerComplete: {
    confirmLabel: 'OK',
    message: 'The checkout timer has expired and your cart reset.',
    title: 'Cart Timeout',
  },
  creditCardError: {
    cancelLabel: null,
    confirmLabel: 'OK',
    message:
      'There was a problem processing your credit card with the specified information. Please try again.',
    title: 'Credit Card Error',
  },
  removeFromCart: {
    cancelLabel: 'No',
    confirmLabel: 'Yes',
    message: 'Are you sure you want to remove this project from your cart?',
  },
  removeFromCartError: {
    confirmLabel: 'OK',
    message: ERROR_MESSAGES.REMOVE_PROJECT_WEBHOOK,
    title: 'Cart Error',
  },
}

export const DEFAULT_USER_INFO = {
  address1: '',
  address2: '',
  cardNumber: '',
  city: '',
  company: '',
  email: '',
  fullName: '',
  paymentType: 'Pay Now',
  phone: '',
  stateProvince: '',
  zipPostal: '',
}

export const FORM_SUBMIT_STATUSES = {
  ERROR: 'error',
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
}

export const FUNDING_STATUSES = {
  AVAILABLE: 'Available',
  CLAIMED: 'Claimed',
  PENDING: 'Pending',
  UNAVAILABLE: 'Unavailable',
}

export const GRADIENTS = {
  NAV_BAR_BLACK: {
    background:
      'linear-gradient(90deg, rgba(36,36,36,1) 0%, rgba(28,28,28,1) 50%, rgba(16,16,16,1) 100%)',
    backgroundSolid: 'rgb(136,81,252)',
  },
}

export const LOCAL_STORAGE_PREFIX = 'mi:mc:aa:'

export const LOCAL_STORAGE_KEYS = {
  activeProjectType: `${LOCAL_STORAGE_PREFIX}activeProjectType`,
  cart: `${LOCAL_STORAGE_PREFIX}cart`,
  projectStatuses: `${LOCAL_STORAGE_PREFIX}projectStatuses`,
  sheetsData: `${LOCAL_STORAGE_PREFIX}sheetsData`,
  userInfo: `${LOCAL_STORAGE_PREFIX}userInfo`,
}

export const PAYMENT_TYPES = {
  PAY_NOW: 'Pay Now',
  CHECK: 'Check',
  FUTURE_INTENT: 'Future Intent to Pay',
}

export const PROJECT_TYPES = {
  ABOUT: {
    id: 0,
    icon: null,
    label: 'About',
    tabPanelPrefix: 'about-tab',
    title: 'About',
    type: 'About',
  },
  DISTRIBUTION: {
    id: 1,
    icon: (
      <DistributionIcon
        color={COLORS.WHITE}
        style={{ height: 'auto', width: '1.25rem' }}
      />
    ),
    label: 'Distribution',
    tabPanelPrefix: 'distribution-projects-tab',
    title: 'Distribution Projects',
    type: 'Distribution',
  },
  TRANSLATION: {
    id: 2,
    icon: (
      <TranslationIcon
        color={COLORS.WHITE}
        style={{ height: 'auto', width: '1.25rem' }}
      />
    ),
    label: 'Translation',
    tabPanelPrefix: 'translation-projects-tab',
    title: 'Translation Projects',
    type: 'Translation',
  },
}

export const STRIPE_KEYS = {
  PUBLISHABLE: {
    LIVE: 'pk_live_r99VXJxhoBfLj3mgzHNKvVWU',
    TEST: 'pk_test_W5wZ5etFn9efqxvAsreB4sRJ',
  },
}

export const TIMER_COMMANDS = {
  PAUSE: 'pause',
  RESET: 'reset',
  START: 'start',
  STOP: 'stop',
}

export const CA_PROVINCES = {
  AB: {
    abbreviation: 'AB',
    name: 'Alberta',
  },
  BC: {
    abbreviation: 'BC',
    name: 'British Columbia',
  },
  MB: {
    abbreviation: 'MB',
    name: 'Manitoba',
  },
  NB: {
    abbreviation: 'NB',
    name: 'New Brunswick',
  },
  NL: {
    abbreviation: 'NL',
    name: 'Newfoundland and Labrador',
  },
  NS: {
    abbreviation: 'NS',
    name: 'Nova Scotia',
  },
  NU: {
    abbreviation: 'NU',
    name: 'Nunavut',
  },
  ON: {
    abbreviation: 'ON',
    name: 'Ontario',
  },
  PE: {
    abbreviation: 'PE',
    name: 'Prince Edward Island',
  },
  QC: {
    abbreviation: 'QC',
    name: 'Quebec',
  },
  SK: {
    abbreviation: 'SK',
    name: 'Saskatchewan',
  },
  NT: {
    abbreviation: 'NT',
    name: 'Northwest Territories',
  },
  YT: {
    abbreviation: 'YT',
    name: 'Yukon',
  },
}

export const US_STATES = {
  AL: {
    abbreviation: 'AL',
    name: 'Alabama',
  },
  AK: {
    abbreviation: 'AK',
    name: 'Alaska',
  },
  AZ: {
    abbreviation: 'AZ',
    name: 'Arizona',
  },
  AR: {
    abbreviation: 'AR',
    name: 'Arkansas',
  },
  CA: {
    abbreviation: 'CA',
    name: 'California',
  },
  CO: {
    abbreviation: 'CO',
    name: 'Colorado',
  },
  CT: {
    abbreviation: 'CT',
    name: 'Connecticut',
  },
  DC: {
    abbreviation: 'DC',
    name: 'District of Columbia',
  },
  DE: {
    abbreviation: 'DE',
    name: 'Delaware',
  },
  FL: {
    abbreviation: 'FL',
    name: 'Florida',
  },
  GA: {
    abbreviation: 'GA',
    name: 'Georgia',
  },
  HI: {
    abbreviation: 'HI',
    name: 'Hawaii',
  },
  ID: {
    abbreviation: 'ID',
    name: 'Idaho',
  },
  IL: {
    abbreviation: 'IL',
    name: 'Illinois',
  },
  IN: {
    abbreviation: 'IN',
    name: 'Indiana',
  },
  IA: {
    abbreviation: 'IA',
    name: 'Iowa',
  },
  KS: {
    abbreviation: 'KS',
    name: 'Kansas',
  },
  KY: {
    abbreviation: 'KY',
    name: 'Kentucky',
  },
  LA: {
    abbreviation: 'LA',
    name: 'Louisiana',
  },
  ME: {
    abbreviation: 'ME',
    name: 'Maine',
  },
  MD: {
    abbreviation: 'MD',
    name: 'Maryland',
  },
  MA: {
    abbreviation: 'MA',
    name: 'Massachusetts',
  },
  MI: {
    abbreviation: 'MI',
    name: 'Michigan',
  },
  MN: {
    abbreviation: 'MN',
    name: 'Minnesota',
  },
  MS: {
    abbreviation: 'MS',
    name: 'Mississippi',
  },
  MO: {
    abbreviation: 'MO',
    name: 'Missouri',
  },
  MT: {
    abbreviation: 'MT',
    name: 'Montana',
  },
  NE: {
    abbreviation: 'NE',
    name: 'Nebraska',
  },
  NV: {
    abbreviation: 'NV',
    name: 'Nevada',
  },
  NH: {
    abbreviation: 'NH',
    name: 'New Hampshire',
  },
  NJ: {
    abbreviation: 'NJ',
    name: 'New Jersey',
  },
  NM: {
    abbreviation: 'NM',
    name: 'New Mexico',
  },
  NY: {
    abbreviation: 'NY',
    name: 'New York',
  },
  NC: {
    abbreviation: 'NC',
    name: 'North Carolina',
  },
  ND: {
    abbreviation: 'ND',
    name: 'North Dakota',
  },
  OH: {
    abbreviation: 'OH',
    name: 'Ohio',
  },
  OK: {
    abbreviation: 'OK',
    name: 'Oklahoma',
  },
  OR: {
    abbreviation: 'OR',
    name: 'Oregon',
  },
  PA: {
    abbreviation: 'PA',
    name: 'Pennsylvania',
  },
  RI: {
    abbreviation: 'RI',
    name: 'Rhode Island',
  },
  SC: {
    abbreviation: 'SC',
    name: 'South Carolina',
  },
  SD: {
    abbreviation: 'SD',
    name: 'South Dakota',
  },
  TN: {
    abbreviation: 'TN',
    name: 'Tennessee',
  },
  TX: {
    abbreviation: 'TX',
    name: 'Texas',
  },
  UT: {
    abbreviation: 'UT',
    name: 'Utah',
  },
  VT: {
    abbreviation: 'VT',
    name: 'Vermont',
  },
  VA: {
    abbreviation: 'VA',
    name: 'Virginia',
  },
  WA: {
    abbreviation: 'WA',
    name: 'Washington',
  },
  WV: {
    abbreviation: 'WV',
    name: 'West Virginia',
  },
  WI: {
    abbreviation: 'WI',
    name: 'Wisconsin',
  },
  WY: {
    abbreviation: 'WY',
    name: 'Wyoming',
  },
}
